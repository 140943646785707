import { PHARMACY_CONTACT } from "../content/pageLinks";
import { APP_BASE_URL } from "../service/apis";

const CONTACT = {
  name: "University Specialty Pharmacy",
  address: ["2338 2nd Ave", "New York, NY 10035"],
  phone: "(917) 464-5252",
  fax: "(914) 290-4541",
  email: "info@universitynyc.com",
  hours: ["Mon-Fri: 9am - 6pm", "Sat: 9am - 3pm", "Sun: Closed"],
};
export const SOCIAL_MEDIA_LINKS = {
  facebook: "https://www.facebook.com/",
  instagram: "https://www.instagram.com/",
  twitter: "https://x.com/",
};
export const COPYRIGHT = `© 2024 ${CONTACT.name}.`;

export const MAIL_TO_LINK = `mailto:${CONTACT.email}`;
export const GEO_LINK =
  "http://maps.google.com/maps/dir//University+Specialty+pharmacy,+2338+2nd+Ave,+New+York,+NY+10035/@40.7993903,-73.9357312,15z/data=!4m17!1m7!3m6!1s0x89c2f741451373c1:0x72d34b30b242bee0!2sUniversity+Specialty+pharmacy!8m2!3d40.7993903!4d-73.9357312!16s%2Fg%2F11glfb2fz4!4m8!1m0!1m5!1m1!1s0x89c2f741451373c1:0x72d34b30b242bee0!2m2!1d-73.9357312!2d40.7993903!3e3?";
// https://www.google.com/maps/dir//898+E+Tremont+Ave,+Bronx,+NY+10460
export const CONTACT_HTML = {
  name: CONTACT.name,
  address: [<b>{CONTACT.name}</b>, ...CONTACT.address],
  phone: CONTACT.phone,
  fax: CONTACT.fax,
  email: <a href={MAIL_TO_LINK}>{CONTACT.email}</a>,
  hours: [...CONTACT.hours],
  pageLink: `${APP_BASE_URL}${PHARMACY_CONTACT}`,
};

export default CONTACT;
