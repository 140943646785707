import React from "react";

export default function Hiv() {
  return (
    <div>
      <h1>HIV</h1>
      <p>This is the HIV page.</p>
    </div>
  );
}
