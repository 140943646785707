import React from "react";
import styled from "styled-components";
import { CONTACT_HTML } from "../../content/contact";
import theme from "../../theme/theme";
export default function PharmacyContact() {
  return (
    <PharmacyContactContainer>
      <PharmacyContactTitle>Pharmacy Contact</PharmacyContactTitle>
      <PharmacyContactInfo>
        <PharmacyContactCardRow>
          <PharmacyContactCard style={{ alignItems: "flex-start" }}>
            <PharmacyContactCardLabel>Address</PharmacyContactCardLabel>
            {CONTACT_HTML.address.map((line) => (
              <PharmacyContactCardLine key={line}>
                {line}
              </PharmacyContactCardLine>
            ))}
          </PharmacyContactCard>
          <PharmacyContactCard style={{ alignItems: "flex-end" }}>
            <PharmacyContactCardLabel>Hours</PharmacyContactCardLabel>
            {CONTACT_HTML.hours.map((line) => (
              <PharmacyContactCardLine key={line}>
                {line}
              </PharmacyContactCardLine>
            ))}
          </PharmacyContactCard>
        </PharmacyContactCardRow>
        <PharmacyContactCardRow>
          <PharmacyContactCard>
            <PharmacyContactCardLabel>Phone</PharmacyContactCardLabel>
            <PharmacyContactCardLine>
              {CONTACT_HTML.phone}
            </PharmacyContactCardLine>
          </PharmacyContactCard>
          <PharmacyContactCard>
            <PharmacyContactCardLabel>Fax</PharmacyContactCardLabel>
            <PharmacyContactCardLine>
              {CONTACT_HTML.fax}
            </PharmacyContactCardLine>
          </PharmacyContactCard>
          <PharmacyContactCard>
            <PharmacyContactCardLabel>Email</PharmacyContactCardLabel>
            <PharmacyContactCardLine>
              {CONTACT_HTML.email}
            </PharmacyContactCardLine>
          </PharmacyContactCard>
        </PharmacyContactCardRow>
      </PharmacyContactInfo>
    </PharmacyContactContainer>
  );
}

const PharmacyContactContainer = styled.div`
  margin: auto;
  padding: 20px;
  max-width: fit-content;
  min-height: 40vh;
`;

const PharmacyContactTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
`;

const PharmacyContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const PharmacyContactCardRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const PharmacyContactCard = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    align-items: flex-start !important;
  }
`;

const PharmacyContactCardLabel = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: ${theme.primary.dark};
`;

const PharmacyContactCardLine = styled.p`
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: 1.5;
`;
