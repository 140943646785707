import React from "react";

export default function Rheumatology() {
  return (
    <div>
      <h1>Rheumatology</h1>
      <p>This is the Rheumatology page.</p>
    </div>
  );
}
