import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme/theme";

export const ReferralFormsOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5%;
  gap: 30px;
  @media screen and (max-width: 560px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ReferralFormsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media screen and (max-width: 560px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ReferralFormTitle = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  color: ${theme.primary.dark};
  color: #555555;
  /* text-decoration: underline; */
  box-sizing: border-box;
  text-transform: capitalize;
  @media screen and (max-width: 560px) {
    font-size: 1.5rem;
  }
`;

export const ReferralFormLinkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;

export const ReferralFormLink = styled(Link)`
  text-decoration: none;
  text-transform: capitalize;
  padding: 10px 15px;
  color: #343a40;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  color: #343a40;
  border-radius: 10px;
  color: ${theme.primary.dark};

  @media screen and (max-width: 560px) {
    font-size: 1rem;
  }
  &:hover {
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
`;
