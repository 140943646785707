import React from "react";
import styled, { keyframes } from "styled-components";
import { getTextColor } from "../helperLogic/formatColor";

export default function AsyncButton(props) {
  const { loading, disabled, color, onClick, children, ...rest } = props;

  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled || loading}
      loading={loading}
      color={color}
      {...rest}
    >
      {children}
    </StyledButton>
  );
}

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const StyledButton = styled.button`
  align-items: center;
  text-align: center;
  padding: 15px 20px;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  background-color: ${({ loading, disabled, color }) =>
    loading || disabled ? `${color}c3` : color};
  color: ${({ color }) => getTextColor(color)};
  border: 2px solid ${({ color }) => color};
  border-radius: 10px;
  &:hover {
    background-color: ${({ color, loading }) =>
      loading ? color : getTextColor(color)};
    color: ${({ color, loading }) => (loading ? getTextColor(color) : color)};
  }
  &:disabled {
    cursor: not-allowed;
  }
  &::before {
    content: "";
    border-radius: 50%;
    display: ${({ loading }) => (loading ? "inline-block" : "none")};
    margin-right: ${({ loading }) => (loading ? "8px" : "0px")};
    /* opacity: ${({ loading }) => (loading ? "1" : "0")}; */
    width: ${({ loading }) => (loading ? "14px" : "0px")};
    height: ${({ loading }) => (loading ? "14px" : "0px")};
    border: 2px solid ${({ color }) => getTextColor(color)};
    border-top-color: transparent;
    animation: ${rotate} 1s linear infinite;
    transition: all 300ms;
    box-sizing: border-box;
  }
`;
