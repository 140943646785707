export const MEDICINE_TITLES = [
  "Acetaminophen",
  "Adderall",
  "Albuterol",
  "Amoxicillin",
  "Ativan",
  "Azithromycin",
  "Ciprofloxacin",
  "Citalopram",
  "Clindamycin",
  "Clonazepam",
  "Codeine",
  "Cyclobenzaprine",
  "Cymbalta",
  "Doxycycline",
  "Gabapentin",
  "Hydrochlorothiazide",
  "Hydrocodone",
  "Ibuprofen",
  "Levothyroxine",
  "Lisinopril",
  "Lorazepam",
  "Losartan",
  "Lyrica",
  "Meloxicam",
  "Metformin",
  "Methadone",
  "Methylphenidate",
  "Metoprolol",
  "Naproxen",
  "Omeprazole",
  "Oxycodone",
  "Pantoprazole",
  "Prednisone",
  "Propranolol",
  "Sertraline",
  "Simvastatin",
  "Tramadol",
  "Trazodone",
  "Viagra",
  "Vicodin",
  "Xanax",
  "Zoloft",
  "Zolpidem",
  "Zyrtec",
  "Vitamin D",
  "Vitamin B12",
  "Vitamin C",
  "Vitamin E",
  "Vitamin B6",
  "Vitamin B1",
  "Vitamin B2",
  "Vitamin B3",
  "Vitamin B5",
  "Vitamin B7",
  "Vitamin B9",
  "Vitamin K",
  "Vitamin A",
  "Vitamin B",
  "Vitamin B Complex",
];
