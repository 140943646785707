export const getSearchQuery = (query) => {
  let queryResult = "?";
  for (const key of Object.keys(query)) {
    if (query[key]) {
      queryResult += `${key}=${query[key]}&`;
    }
  }
  return queryResult.slice(0, -1);
};

export const formateDateToDDMMMYYYY = (date) => {
  if (date.trim() === "") return "";
  const dateObj = new Date(date);
  return `
  ${dateObj.getDate()}-${dateObj.toLocaleString("default", {
    month: "long",
  })}-${dateObj.getFullYear()}
  `;
};

export const hoc = (Component) => (props) => {
  console.log(props);
  return <Component {...props} />;
};

export const getPlaceDetails = (placeService, placeId, callback) => {
  if (!placeId) return;
  // console.log(placeId);
  placeService.getDetails(
    {
      placeId: placeId,
      fields: [
        // "name",
        // "formatted_address",
        "formatted_phone_number",
        // "geometry",
        // "place_id",
        // "rating",
        // "url",
        // "photos",
      ],
    },
    (place, status) => {
      if (status === "OK") {
        callback({
          // address: place.formatted_address,
          phone: place.formatted_phone_number,
          ...place,
        });
      }
    }
  );
};
