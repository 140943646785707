import axios from "axios";
import { API_SEND_EMAIL } from "./apis";
import { EMPTY_FUNCTION } from "../theme/variables";

export const sendTransferRxMailFn = ({
  from = "Transfer RX USP <info@universitynyc.com>",
  to = "info@universitynyc.com",
  subject = "Transfer Request",
  message = "Data Not found",
  onSuccess = EMPTY_FUNCTION,
  onError = EMPTY_FUNCTION,
  onFinally = EMPTY_FUNCTION,
}) =>
  axios({
    method: "post",
    url: API_SEND_EMAIL,
    data: {
      from,
      to,
      subject,
      message,
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      console.log("response", response);
      onSuccess();
    })
    .catch((error) => {
      console.log("error", error);
      onError();
    })
    .finally(() => onFinally());
