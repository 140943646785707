import React from "react";

import { Routes, Route } from "react-router-dom";

import ErrorPage from "../ErrorPage";
import PatientOverView from "./PatientOverview";
import PharmacyContact from "../PharmacyContact";
import AccountSetup from "./AccountSetup";
import TransferPrescription from "./TransferPrescription";

export default function Patients() {
  return (
    <Routes>
      <Route path="/patient-overview" element={<PatientOverView />} />
      <Route path="/transfer-prescription" element={<TransferPrescription />} />
      <Route path="/pharmacy-contact" element={<PharmacyContact />} />
      <Route path="/account-setup" element={<AccountSetup />} />
      <Route index element={<PatientOverView />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
