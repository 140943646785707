import React from "react";
import styled from "styled-components";
import { AUTH_PAGE_LINKS } from "../../../content/pageLinks";
import { Link } from "react-router-dom";
import theme from "../../../theme/theme";
import { HEADER_HEIGHT } from "../../../theme/variables";
import AsyncButton from "../../../atoms/Buttons/AsyncButton";

export default function Login() {
  return (
    <LoginContainer>
      <LoginTitle>Login</LoginTitle>
      <LoginForm>
        <Input type="email" placeholder="Email" />
        <Input type="password" placeholder="Password" />
        <AsyncButton
          type="submit"
          onClick={() =>
            setTimeout(() => {
              console.log("CCC");
            }, 3000)
          }
        >
          Login
        </AsyncButton>
      </LoginForm>
      <LoginFooter>
        <Typography>
          Don't Have an account? -{" "}
          <TypographyLink
            to={`${AUTH_PAGE_LINKS.main}${AUTH_PAGE_LINKS.REGISTER}`}
          >
            Join Now
          </TypographyLink>
        </Typography>
        <TypographyLink
          to={AUTH_PAGE_LINKS.main + AUTH_PAGE_LINKS.FORGOT_PASSWORD}
        >
          Forgot Password?
        </TypographyLink>
      </LoginFooter>
    </LoginContainer>
  );
}

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${HEADER_HEIGHT});
  width: 100%;
  background-color: #ffe49296;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
`;

const LoginTitle = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #343a40;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  color: #343a40;
  box-sizing: border-box;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  gap: 20px;

  & > * {
    width: 100%;
  }
  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  & > *:focus {
    outline: none;
  }
  & > *:focus::placeholder {
    color: transparent;
  }
  & > *::placeholder {
    color: #343a40;
  }
`;

const Input = styled.input`
  border: none;
  outline: none;
  padding: 0 0.5rem;
  border-bottom: 1px solid #333;
  font-size: 1rem;
  font-weight: 400;
  color: #343a40;
  box-sizing: border-box;
  &:hover {
    border-bottom: 2px solid ${theme.primary.main};
  }
  &:focus {
    border-bottom: 2px solid ${theme.primary.main};
  }
  &:focus::placeholder {
    color: transparent;
    color: #343a40;
  }
`;

const LoginFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
`;

const Typography = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #343a40;
  box-sizing: border-box;
`;

const TypographyLink = styled(Link)`
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: ${theme.primary.dark};
  &:hover {
    border-bottom: 2px solid ${theme.primary.dark};
  }
  &:active {
    color: ${theme.primary.main};
  }
`;
