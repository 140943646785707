import React from "react";
import TextField from "@mui/material/TextField";

export default function TextInput({ children, ...restProps }) {
  return (
    <TextField variant="standard" fullWidth {...restProps}>
      {children}
    </TextField>
  );
}
