import React from "react";
import {
  BannerContent,
  BannerImage,
  BannerInnerContainer,
  BannerOuterContainer,
  BannerTitle,
} from "./styles";

export default function ColorBanner({
  style,
  title,
  imgUrl,
  markerUrl = "https://img.icons8.com/?size=100&id=gw7xP6ZiA66C&format=png&color=000000",
  children,
}) {
  return (
    <BannerOuterContainer style={style}>
      <BannerTitle>{title}</BannerTitle>
      <BannerInnerContainer>
        <BannerImage>
          <img src={imgUrl} alt="" />
        </BannerImage>
        <BannerContent markerUrl={markerUrl}>{children}</BannerContent>
      </BannerInnerContainer>
    </BannerOuterContainer>
  );
}
