import styled from "styled-components";
import theme from "../../theme/theme";
import { Link } from "react-router-dom";

export const FourColumnServicesOuterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: left;
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const FourColumnServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1rem;
  color: #ffffff;
  background-image: url(${(props) => props?.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:nth-child(n) {
    box-shadow: inset 0 0 0 2000px ${theme.primary.dark + "cc"};
  }
  &:nth-child(even) {
    box-shadow: inset 0 0 0 2000px ${theme.blue.dark + "cc"};
  }

  @media screen and (max-width: 1024px) {
    &:nth-child(n) {
      box-shadow: inset 0 0 0 2000px ${theme.primary.dark + "cc"};
    }
    &:nth-child(2) {
      box-shadow: inset 0 0 0 2000px ${theme.blue.dark + "cc"};
    }
    &:nth-child(3) {
      box-shadow: inset 0 0 0 2000px ${theme.blue.dark + "cc"};
    }
  }
`;

export const FourColumnServiceTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  @media screen and (max-width: 560px) {
    font-size: 1rem;
  }
`;

export const FourColumnServiceDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  @media screen and (max-width: 560px) {
    font-size: 0.8rem;
  }
`;

export const FourColumnServiceLinkButton = styled(Link)`
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
`;
