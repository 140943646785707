import React from "react";

export default function Dermatology() {
  return (
    <div>
      <h1>Dermatology</h1>
      <p>This is the Dermatology page.</p>
    </div>
  );
}
