import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../theme/theme";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

export default function PopupNavbarItem(props) {
  const { item } = props;
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenSubItem = () => setIsOpen(true);
  const handleCloseSubItem = () => setIsOpen(false);
  return (
    <PopupNavbarItemContainerSC>
      <PopupNavbarItemSC>
        <PopupNavbarItemLabel to={item?.pageLink}>
          {item.title}
        </PopupNavbarItemLabel>
        {item?.children &&
          (isOpen ? (
            <UpOutlined onClick={handleCloseSubItem} />
          ) : (
            <DownOutlined onClick={handleOpenSubItem} />
          ))}
      </PopupNavbarItemSC>
      {isOpen &&
        item.children.map((subItem) => (
          <PopupNavbarSubItemContainerSC key={subItem.key}>
            <PopupNavbarItem item={subItem} />
          </PopupNavbarSubItemContainerSC>
        ))}
    </PopupNavbarItemContainerSC>
  );
}

const PopupNavbarSubItemContainerSC = styled.div`
  margin: 0;
  margin-left: 20px;
`;

const PopupNavbarItemLabel = styled(NavLink)`
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  color: #343a40;
  box-sizing: border-box;
  &:hover {
    border-bottom: 2px solid ${theme.primary.main};
  }
`;

const PopupNavbarItemSC = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
`;

const PopupNavbarItemContainerSC = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #e0e0e0;
  &:hover {
    background-color: #cccccc;
  }
`;
