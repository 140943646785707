import styled from "styled-components";

export const BannerOuterContainer = styled.div`
  background-image: url("/static/images/Website_Header_1920x1080_M.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  & > * {
    width: clamp(300px, 40%, 460px);
  }
  @media screen and (max-width: 560px) {
    padding: 3rem;
  }
`;

export const BannerTitle = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  word-spacing: 0.4rem;
  margin: 0;
  padding: 0;
  color: #000000;
  @media screen and (max-width: 560px) {
    font-size: 1.2rem;
  }
`;

export const BannerDescription = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  word-spacing: 0.3rem;
  margin: 0;
  padding: 0;
  color: #222222;
  @media screen and (max-width: 560px) {
    font-size: 1rem;
  }
`;
