export const HEADER_HEIGHT = "150px";
export const HEADER_HEIGHT_MIN = "80px";
export const HEADER_HEIGHT_DIFFERENCE = "70px";

export const Z_INDEX = {
  HEADER: 5,
};

export const EMPTY_FUNCTION = () => {};
export const EMPTY_STRING = "";
export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
