import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import InputMask from "react-input-mask";

import TextInput from "../../../../atoms/Inputs/TextInput";

import * as StepSC from "./StepComponent";
import { STEPS, STEP_META_DATA } from "./utils";

export default function Doctor({ ptTransferData, setStepData }) {
  const navigate = useNavigate();

  const stepMetaData = STEP_META_DATA[STEPS.doctor];
  const doctorInfo = ptTransferData[STEPS.doctor];
  const setDoctorInfo = setStepData(STEPS.doctor);

  const formik = useFormik({
    initialValues: { ...doctorInfo },
    validationSchema: yup.object({
      doctorName: yup.string().required("Add your doctor's name"),
      doctorPhone: yup
        .string()
        .test(
          "format",
          "Invalid phone format",
          (value) => value.replace(/-|_/g, "").length === 10
        )
        .required("Your phone number is required"),
    }),
    onSubmit: (values) => {
      setDoctorInfo(values);
      navigate(stepMetaData.goNext.linkTo, {
        state: { from: STEPS.doctor },
      });
    },
  });

  return (
    <StepSC.OuterContainer>
      <StepSC.NavBar>
        <StepSC.LinkTextButton to={stepMetaData.goBack.linkTo}>
          <LeftOutlined /> <span>{stepMetaData.goBack.text}</span>
        </StepSC.LinkTextButton>
        <StepSC.Counter>
          {stepMetaData.step.current} of {stepMetaData.step.total}
        </StepSC.Counter>
      </StepSC.NavBar>
      <StepSC.Hero>
        <StepSC.Title>{stepMetaData.title}</StepSC.Title>
        <StepSC.Description>{stepMetaData.description}</StepSC.Description>
        <StepSC.Form onSubmit={formik.handleSubmit}>
          <TextInput
            id="doctorName"
            name="doctorName"
            label="Doctor's Name"
            value={formik.values.doctorName}
            onChange={formik.handleChange}
            error={
              formik.touched.doctorName && Boolean(formik.errors.doctorName)
            }
            helperText={formik.touched.doctorName && formik.errors.doctorName}
          />
          <InputMask
            id="doctorPhone"
            name="doctorPhone"
            label="Doctor's Phone Number"
            mask="999-999-9999"
            value={formik.values.doctorPhone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {(inputProps) => (
              <TextInput
                {...inputProps}
                type="tel"
                disableUnderline
                error={formik.touched.doctorPhone && formik.errors.doctorPhone}
                helperText={
                  formik.touched.doctorPhone && formik.errors.doctorPhone
                }
              />
            )}
          </InputMask>
          <StepSC.LinkButton onClick={formik.handleSubmit}>
            {stepMetaData.goNext.text}
          </StepSC.LinkButton>
        </StepSC.Form>
      </StepSC.Hero>
    </StepSC.OuterContainer>
  );
}
