import React from "react";

export default function Gastroenterology() {
  return (
    <div>
      <h1>Gastroenterology</h1>
      <p>This is the Gastroenterology page.</p>
    </div>
  );
}
