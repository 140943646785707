import React from "react";
import {
  FourColumnServiceContainer,
  FourColumnServiceDescription,
  FourColumnServiceLinkButton,
  FourColumnServiceTitle,
  FourColumnServicesOuterContainer,
} from "./styles";

export default function FourColumnServices({ content }) {
  return (
    <FourColumnServicesOuterContainer>
      {content.map((service, index) => {
        return (
          <FourColumnServiceContainer
            key={index}
            backgroundImage={service?.backgroundImage}
          >
            <FourColumnServiceTitle>{service.title}</FourColumnServiceTitle>
            <FourColumnServiceDescription>
              {service.description}
            </FourColumnServiceDescription>
            <FourColumnServiceLinkButton to={service.linkTo}>
              Learn More
            </FourColumnServiceLinkButton>
          </FourColumnServiceContainer>
        );
      })}
    </FourColumnServicesOuterContainer>
  );
}
