import React from "react";

export default function SpecialtiesOverview() {
  return (
    <div>
      <h1>Specialties Overview</h1>
      <p>Welcome to the Specialties Overview page.</p>
    </div>
  );
}
