import React from "react";

export default function Ophthalmology() {
  return (
    <div>
      <h1>Ophthalmology</h1>
      <p>This is the Ophthalmology page.</p>
    </div>
  );
}
