import React from "react";
import styled from "styled-components";
import { HEADER_HEIGHT_MIN } from "../../../theme/variables";
import { useSearchParams } from "react-router-dom";
import Pharmacy from "./steps/Pharmacy";
import Doctor from "./steps/Doctor";
import Medicine from "./steps/Medicine";
import { STEPS, STEP_META_DATA, defaultFormValues } from "./steps/utils";
import TransferRx from "./steps/TransferRx";
import Patient from "./steps/Patient";
import ShowToYourDoctor from "./steps/ShowToYourDoctor";
import Summary from "./steps/Summary";
import LinearProgress from "@mui/material/LinearProgress";
import theme from "../../../theme/theme";

const hoc = (Component) => (props) => {
  return <Component {...props} />;
};

const Step = ({ curStep, ...restProps }) => {
  switch (curStep) {
    case STEPS.patient:
      return <Patient {...restProps} />;
    case STEPS.transferRx:
      return <TransferRx {...restProps} />;
    case STEPS.pharmacy:
      return <Pharmacy {...restProps} />;
    case STEPS.doctor:
      return <Doctor {...restProps} />;
    case STEPS.medicine:
      return <Medicine {...restProps} />;
    case STEPS.summary:
      return <Summary {...restProps} />;
    case STEPS.show_to_your_doctor:
      return <ShowToYourDoctor {...restProps} />;
    default:
      return <Patient {...restProps} />;
  }
};

export default function TransferPrescription() {
  const [searchParams] = useSearchParams();
  const curStep = searchParams.get("step") || STEPS.patient;
  const { current, total } = STEP_META_DATA[curStep].step;
  // console.log(curStep, Number((current * 100) / total).toFixed(0));
  const [ptTransferData, setPtTransferData] = React.useState(defaultFormValues);

  const setStepData = (step) => (data) => {
    setPtTransferData({
      ...ptTransferData,
      [step]: data,
    });
  };

  return (
    <TransferPrescriptionSC>
      <LinearProgress
        variant="determinate"
        value={Number((current * 100) / total).toFixed(0)}
        sx={{
          position: "sticky",
          top: HEADER_HEIGHT_MIN,
          height: "8px",
          left: 0,
          borderBottom: `1px solid white`,
          backgroundColor: `${theme.secondary.light}33`,
          "& .MuiLinearProgress-bar": {
            backgroundColor: theme.secondary.light,
            borderRadius: "10px",
          },
        }}
      />
      <StepsProgress></StepsProgress>
      <StepsScreenSC>
        {hoc(Step)({ curStep, ptTransferData, setStepData })}
      </StepsScreenSC>
    </TransferPrescriptionSC>
  );
}

const TransferPrescriptionSC = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  /* border: 3px solid red; */
`;

const StepsProgress = styled.div`
  flex-grow: 0;
  width: clamp(150px, 15%, 300px);
  height: 10px;
  position: sticky;
  top: ${HEADER_HEIGHT_MIN};
  left: 0;
  /* background-color: red; */
  @media screen and (max-width: 560px) {
    display: none;
  }
`;

const StepsScreenSC = styled.div`
  flex-grow: 1;
  display: flex;
  margin: auto;
  margin-top: 4%;
  margin-bottom: 10%;
  justify-content: center;
  /* border: 3px solid blue; */
  width: 40%;
  max-width: 600px;
  @media screen and (max-width: 560px) {
    margin-top: 4%;
    margin-bottom: 10%;
    padding: 0 3%;
    width: 100%;
  }
`;
