import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "../../../../theme/theme";
import { getTextColor } from "../../../../atoms/helperLogic/formatColor";

export const OptionDescription = styled.p`
  grid-area: optionDescription;
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  font-weight: 500;
`;

export const OptionTitle = styled.h2`
  grid-area: optionTitle;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

export const OptionImage = styled.img`
  grid-area: optionImage;
  width: 90%;
  margin: auto;
  object-fit: contain;
  border-radius: 10px;
`;

export const OptionCard = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: grid;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid ${theme.primary.dark};
  background-color: white;
  color: ${theme.primary.dark};
  box-sizing: border-box;
  &:hover,
  &:focus {
    background-color: ${theme.primary.light + "cc"};
    color: white;
  }
  /* width: 90%; */
  grid-template-areas: "optionImage optionTitle" "optionImage optionDescription";
  grid-template-columns: 2fr 8fr;
  grid-template-rows: 1fr 1fr;
`;

export const ActionItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  & > * {
    flex: 1 0 40%;
  }
  @media screen and (max-width: 560px) {
    /* font-size: 0.8rem; */
    justify-content: stretch;
    & > * {
      flex: 1 0 100%;
    }
  }
`;

export const ActionLongItem = styled.div`
  width: 100%;
`;

export const ActionBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 3%;
`;

export const Description = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  @media screen and (max-width: 560px) {
    font-size: 0.8rem;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  @media screen and (max-width: 560px) {
    font-size: 1.5rem;
  }
`;

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  padding: 3%;
  cursor: default;
`;

export const Counter = styled.div``;

export const LinkTextButton = styled(Link)`
  text-decoration: none;
  text-transform: capitalize;
  color: ${theme.primary.dark};
  & > :nth-child(1) {
    width: 0.8rem;
  }
  &:hover > span {
    text-decoration: underline;
  }
`;

export const LinkButton = styled(Link)`
  text-decoration: none;
  color: ${getTextColor(theme.primary.light)};
  background-color: ${theme.primary.light};
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  width: max-content;
  &:hover {
    background-color: ${theme.primary.dark};
    color: ${getTextColor(theme.primary.light)};
  }
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
  /* border: 3px solid green; */
`;

export const OuterContainer = styled.div`
  width: 100%;
  /* border: 3px solid orange; */
  row-gap: 20;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-height: 70vh;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
