import React from "react";
import { FORM_TYPES, FORMS } from "./formLinks";
import {
  ReferralFormLink,
  ReferralFormLinkContainer,
  ReferralFormsGroup,
  ReferralFormsOuterContainer,
  ReferralFormTitle,
} from "./styles";
export default function ReferralForms() {
  const [loading, setLoading] = React.useState(true);
  const [allForms, setAllForms] = React.useState([]);
  React.useEffect(() => {
    Object.values(FORM_TYPES).forEach((type) => {
      const curForms = FORMS[type] || [];
      if (curForms.length > 0) {
        curForms?.sort((a, b) => a.name.localeCompare(b.name));
        setAllForms((prev) => [
          ...prev,
          { title: `${type} forms`, forms: curForms },
        ]);
      }
    });
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  console.log(allForms);
  return (
    <ReferralFormsOuterContainer>
      {allForms.map((forms) => (
        <ReferralFormsGroup key={forms.title}>
          <ReferralFormTitle>{forms.title}</ReferralFormTitle>
          <ReferralFormLinkContainer>
            {forms.forms.map((form, index) => (
              <ReferralFormLink to={form.formLink} key={index} target="_blank">
                {form.name}{" "}
                <img
                  width="16"
                  height="16"
                  src="https://img.icons8.com/small/16/export-pdf.png"
                  alt="export-pdf"
                />
              </ReferralFormLink>
            ))}
          </ReferralFormLinkContainer>
        </ReferralFormsGroup>
      ))}
      <ReferralFormsGroup>
        <ReferralFormTitle></ReferralFormTitle>
      </ReferralFormsGroup>
    </ReferralFormsOuterContainer>
  );
}
