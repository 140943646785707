import React, { useState } from "react";
import styled from "styled-components";

import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { HEADER_HEIGHT_MIN, Z_INDEX } from "../../theme/variables";
import LogoButton from "../../atoms/Buttons/LogoButton";
import theme from "../../theme/theme";
import { NavBarData } from "../../content/navBarData";
import PopupNavbarItem from "./PopupNavbarItem";

export default function PopupNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const handleStopBubbling = (e) => e.stopPropagation();
  return (
    <PopupNavbarSC>
      <MenuOutlined onClick={() => setIsOpen(true)} />
      <LogoButton style={{ opacity: isOpen ? 0 : 1, transition: "all 1s" }} />
      <div />
      <PopupNavBarModal isOpen={isOpen} onClick={() => setIsOpen(false)}>
        <PopupNavbarContainer isOpen={isOpen} onClick={handleStopBubbling}>
          <PopupNavBarHeader>
            <LogoButton />
            <CloseOutlined onClick={() => setIsOpen(false)} />
          </PopupNavBarHeader>
          <PopupNavbarItemsContainer onClick={handleStopBubbling}>
            {NavBarData.map((item) => {
              return <PopupNavbarItem key={item.key} item={item} />;
            })}
          </PopupNavbarItemsContainer>
        </PopupNavbarContainer>
      </PopupNavBarModal>
    </PopupNavbarSC>
  );
}

const PopupNavbarSC = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    height: ${HEADER_HEIGHT_MIN};
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: ${Z_INDEX.HEADER};
  }
`;

const PopupNavbarItemsContainer = styled.div`
  @media screen {
    padding: 1rem;
    overflow-y: auto;
  }
`;

const PopupNavBarHeader = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${HEADER_HEIGHT_MIN};
    background: #fff;
    padding: 0 0.5rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }
`;

const PopupNavbarContainer = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    height: 100%;
    background: #fff;
    opacity: 1;
    transform: ${({ isOpen }) =>
      isOpen ? "translateX(0)" : "translateX(-100%)"};
    transition: transform 700ms ease;
  }
`;

const PopupNavBarModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  z-index: ${({ isOpen }) => (isOpen ? Z_INDEX.HEADER : -1)};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  backdrop-filter: ${({ isOpen }) => (isOpen ? "blur(5px)" : "blur(0)")};
  transition: all 0.3s;
  background: ${theme.primary.light + "99"};
`;
