import React from "react";
import { Autocomplete } from "@mui/material";
import { MEDICINE_TITLES } from "../../content/medicine";
import { CloseOutlined } from "@ant-design/icons";
import TextInput from "./TextInput";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

export default function MedicineAutoComplete({
  id,
  name,
  label,
  error,
  helperText,
  value,
  onChange: setValue,
}) {
  return (
    <Autocomplete
      loadingText="Loading..."
      sx={{ width: "100%" }}
      multiple
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      options={MEDICINE_TITLES}
      renderTags={() => null}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option, inputValue, { insideWords: true });
        const parts = parse(option, matches);

        return (
          <li {...props} key={props.key}>
            <div style={{ width: "100%", wordWrap: "normal" }}>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
            {props["aria-selected"] && <CloseOutlined />}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextInput
          {...params}
          id={id}
          name={name}
          label={label}
          placeholder="Search with medicine name"
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
}
