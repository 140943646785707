import React from "react";

import { Routes, Route } from "react-router-dom";

import Cardiology from "./Cardiology";
import Dermatology from "./Dermatology";
import Gastroenterology from "./Gastroenterology";
import Hiv from "./Hiv";
import Oncology from "./Oncology";
import Ophthalmology from "./Ophthalmology";
import Psychiatry from "./Psychiatry";
import Pulmonology from "./Pulmonology";
import Rheumatology from "./Rheumatology";
import SpecialtiesOverview from "./SpecialtiesOverview";
import ErrorPage from "../ErrorPage";

export default function Specialties() {
  return (
    <Routes>
      <Route path="/cardiology" element={<Cardiology />} />
      <Route path="/dermatology" element={<Dermatology />} />
      <Route path="/gastroenterology" element={<Gastroenterology />} />
      <Route path="/hiv" element={<Hiv />} />
      <Route path="/oncology" element={<Oncology />} />
      <Route path="/ophthalmology" element={<Ophthalmology />} />
      <Route path="/psychiatry" element={<Psychiatry />} />
      <Route path="/pulmonology" element={<Pulmonology />} />
      <Route path="/rheumatology" element={<Rheumatology />} />
      <Route index element={<SpecialtiesOverview />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
