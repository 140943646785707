export const theme = {
  primary: {
    main: "#EF5821",
    light: "#FF6B3D",
    dark: "#D94A00",
  },
  secondary: {
    main: "#FFC20F",
    light: "#FFD94A",
    dark: "#FFB800",
  },
  blue: {
    main: "#003366",
    light: "#003F7D",
    dark: "#002347",
  },
  text: {
    primary: "#000",
    secondary: "#FFF",
  },
};

export default theme;
