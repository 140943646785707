import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import NavBar from "./components/Navbar";
import {
  AUTH_PAGE_LINKS,
  PATIENT_PORTAL,
  PROVIDERS_PAGE_LINKS,
} from "./content/pageLinks";
import ErrorPage from "./screens/ErrorPage";
import HomePage from "./screens/HomePage";
import Login from "./screens/Patient/Portal/Login";
import Register from "./screens/Patient/Portal/Register";
import ReferralForms from "./screens/ReferralForms";
import useVisibilityChange from "./hooks/useVisibilityChange";
import { setupNotifications } from "./service/firebase";
import Footer from "./components/Footer";
import Admin from "./screens/Admin";
import Specialties from "./screens/Specialties";
import Patients from "./screens/Patient";
import PharmacyContact from "./screens/PharmacyContact";

function App() {
  const isForeground = useVisibilityChange();
  const [notificationToken, setNotificationToken] = React.useState("");

  useEffect(() => {
    setupNotifications({ notificationToken, setNotificationToken });
  }, [isForeground, notificationToken]);

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/pharmacy-contact" element={<PharmacyContact />} />
        <Route path="/specialties/*" element={<Specialties />} />
        <Route path="/patients/*" element={<Patients />} />
        <Route
          path={PROVIDERS_PAGE_LINKS.REFERRAL_FORM}
          element={<ReferralForms />}
        />
        <Route path={PATIENT_PORTAL}>
          <Route path={AUTH_PAGE_LINKS.LOGIN.slice(1)} element={<Login />} />
          <Route
            path={AUTH_PAGE_LINKS.REGISTER.slice(1)}
            element={<Register />}
          />
          <Route path="*" element={<ErrorPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
