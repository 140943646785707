import React from "react";
import {
  Body,
  Column,
  Container,
  Head,
  Heading,
  Hr,
  Html,
  Img,
  Link,
  Preview,
  Section,
  Text,
  Row,
} from "@react-email/components";

import { STEPS } from "./utils";
import contact from "../../../../content/contact";
import { formateDateToDDMMMYYYY } from "./helper";
import CONTACT from "../../../../content/contact";

const LogoImage = `https://universitynyc.com/static/media/universityLogoNoBg.7a094b139fc8acf21b6d.png`;

export const EmailBody = ({ ptTransferData, children }) => {
  const patientInfo = ptTransferData[STEPS.patient];
  const transferRxInfo = ptTransferData[STEPS.transferRx];
  const pharmacyInfo = ptTransferData[STEPS.pharmacy];
  const doctorInfo = ptTransferData[STEPS.doctor];
  const medicineInfo = ptTransferData[STEPS.medicine];

  return (
    <>
      <Section style={header}>
        <Row>
          <Column style={headerContent}>
            <Heading style={headerContentTitle}>
              Transfer Rx Request Summary
            </Heading>
            <Text style={headerContentSubtitle}>
              We'll contact your pharmacy or doctor for transfer your medicine
              to Our Pharmacy
            </Text>
          </Column>
          <Column style={headerImageContainer}>
            <Img
              style={headerImage}
              width={340}
              src={`https://ideogram.ai/api/images/direct/5JOV2cGaRzmrRBm15cVRPg.png`}
            />
          </Column>
        </Row>
      </Section>
      <Section style={content}>
        <Heading as="h2" style={title}>
          Patient Information
        </Heading>
        <Text style={paragraph}>
          <ul>
            <li>
              Name: {patientInfo.firstName} {patientInfo.lastName}
            </li>
            <li>Phone: {patientInfo.phone}</li>
            <li>Email: {patientInfo.email}</li>
            <li>Date of Birth: {formateDateToDDMMMYYYY(patientInfo.dob)}</li>
            <li>Gender: {patientInfo.gender}</li>
          </ul>
        </Text>
        <Hr style={divider} />
        <Heading as="h2" style={title}>
          Medicine
        </Heading>
        <Text style={paragraph}>
          <ul>
            <li>
              {medicineInfo.transferAll
                ? "Transfer All Medicine"
                : medicineInfo.medicineList?.map((medicine) => (
                    <li key={medicine}>{medicine}</li>
                  ))}
            </li>
          </ul>
        </Text>
        <Hr style={divider} />
        <Heading as="h2" style={paragraph}>
          Transfer Medicine From - {transferRxInfo.transferFrom}
        </Heading>
        <Hr style={divider} />
        {(pharmacyInfo.pharmacyName ||
          pharmacyInfo.pharmacyAddress ||
          pharmacyInfo.pharmacyPhone) && (
          <>
            <Heading as="h2" style={title}>
              Other Pharmacy Information
            </Heading>
            <Text style={paragraph}>
              <ul>
                <li>Name: {pharmacyInfo.pharmacyName}</li>
                <li>Address: {pharmacyInfo.pharmacyAddress}</li>
                <li>Phone: {pharmacyInfo.pharmacyPhone}</li>
              </ul>
            </Text>
            <Hr style={divider} />
          </>
        )}
        {(doctorInfo.doctorName || doctorInfo.doctorAddress) && (
          <>
            <Heading as="h2" style={title}>
              Doctor Information
            </Heading>
            <Text style={paragraph}>
              <ul>
                <li>Name: {doctorInfo.doctorName}</li>
                <li>Phone: {doctorInfo.doctorPhone}</li>
              </ul>
            </Text>
            <Hr style={divider} />
          </>
        )}
        {children || (
          <h4>
            I authorize {CONTACT.name} to contact the transferring pharmacy,
            doctor office, and myself.*
            <br />✅ Yes
          </h4>
        )}
      </Section>
    </>
  );
};

export default function HtmlMailSummary({ ptTransferData }) {
  return (
    <Html>
      <Head />
      <Preview>Transfer Request to University Specialty Pharmacy</Preview>
      <Body style={main}>
        <Container style={container}>
          <Section style={logo}>
            <Img width={246} src={LogoImage} />
          </Section>
          <EmailBody ptTransferData={ptTransferData} />
        </Container>
        <Section style={footer}>
          <Text style={footerText}>
            You're receiving this email because your {contact.name} triggered
            this tip or reminder.
          </Text>
          {/* <Link href={contact.pageLink} style={footerLink}>
            Contact us
          </Link> */}
          <Link href="/" style={footerLink}>
            Privacy
          </Link>
          <Hr style={footerDivider} />
          <Img width={111} src={LogoImage} />
          <Text style={footerAddress}>
            {contact.address[0]}
            <br />
            {contact.address[1]}
            <br />
            {contact.address[2]}
          </Text>
          <Text style={footerHeart}>{"<3"}</Text>
        </Section>
      </Body>
    </Html>
  );
}

const main = {
  backgroundColor: "#f3f3f5",
  fontFamily: "HelveticaNeue,Helvetica,Arial,sans-serif",
  maxWidth: "90vw",
};

const headerContent = { padding: "20px 30px 15px" };

const headerContentTitle = {
  color: "#fff",
  fontSize: "27px",
  fontWeight: "bold",
  lineHeight: "27px",
};

const headerContentSubtitle = {
  color: "#fff",
  fontSize: "17px",
};

const headerImageContainer = {
  padding: "30px 10px",
};

const headerImage = {
  maxWidth: "100%",
};

const title = {
  margin: "0 0 15px",
  fontWeight: "bold",
  fontSize: "21px",
  lineHeight: "21px",
  color: "#0c0d0e",
};

const paragraph = {
  fontSize: "15px",
  lineHeight: "21px",
  color: "#3c3f44",
};

const divider = {
  margin: "30px 0",
};

const container = {
  width: "680px",
  maxWidth: "100%",
  margin: "0 auto",
  backgroundColor: "#ffffff",
};

const footer = {
  width: "680px",
  maxWidth: "100%",
  margin: "32px auto 0 auto",
  padding: "0 30px",
};

const content = {
  padding: window.matchMedia("(min-width: 560px)").matches
    ? "30px 30px 40px 30px"
    : "20px 5px",
};

const logo = {
  display: "flex",
  background: "#f3f3f5",
  padding: "20px 30px",
};

const header = {
  borderRadius: "5px 5px 0 0",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#2b2d6e",
};

// const buttonContainer = {
//   marginTop: "24px",
//   display: "block",
// };

// const button = {
//   backgroundColor: "#0095ff",
//   border: "1px solid #0077cc",
//   fontSize: "17px",
//   lineHeight: "17px",
//   padding: "13px 17px",
//   borderRadius: "4px",
//   maxWidth: "120px",
//   color: "#fff",
// };

const footerDivider = {
  ...divider,
  borderColor: "#d6d8db",
};

const footerText = {
  fontSize: "12px",
  lineHeight: "15px",
  color: "#9199a1",
  margin: "0",
};

const footerLink = {
  display: "inline-block",
  color: "#9199a1",
  textDecoration: "underline",
  fontSize: "12px",
  marginRight: "10px",
  marginBottom: "0",
  marginTop: "8px",
};

const footerAddress = {
  margin: "4px 0",
  fontSize: "12px",
  lineHeight: "15px",
  color: "#9199a1",
};

const footerHeart = {
  borderRadius: "1px",
  border: "1px solid #d6d9dc",
  padding: "4px 6px 3px 6px",
  fontSize: "11px",
  lineHeight: "11px",
  fontFamily: "Consolas,monospace",
  color: "#e06c77",
  maxWidth: "min-content",
  margin: "0 0 32px 0",
};
