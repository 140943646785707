import React from "react";

import CONTACT, {
  COPYRIGHT,
  GEO_LINK,
  SOCIAL_MEDIA_LINKS,
} from "../../content/contact";

import {
  FooterCard,
  FooterCardContent,
  FooterCardIcon,
  FooterCardIconContent,
  FooterCardTitle,
  FooterInnerContainer,
  FooterOuterContainer,
  FooterNavLink,
  SocialMediaIconLink,
} from "./styles";
import MapIcon from "./icons/map.png";
import PhoneIcon from "./icons/phone.png";
import HoursIcon from "./icons/hours.png";
import ExternalLinkIcon from "./icons/externalLink.png";
import FacebookIcon from "./icons/facebook.png";
import TwitterIcon from "./icons/twitter.png";
import InstagramIcon from "./icons/instagram.png";

import LogoButton from "../../atoms/Buttons/LogoButton";
import {
  PHARMACY_CONTACT,
  PATIENTS_PAGE_LINKS,
  SPECIALTIES_PAGE_LINKS,
  PROVIDERS_PAGE_LINKS,
} from "../../content/pageLinks";

export default function Footer() {
  return (
    <FooterOuterContainer>
      <FooterInnerContainer>
        <FooterCard>
          <FooterCardTitle>Contact Information</FooterCardTitle>
          <FooterCardIconContent>
            <FooterCardIcon src={MapIcon} alt="" />
            <FooterCardContent>
              {CONTACT.address.map((addressLine) => (
                <span>{addressLine}</span>
              ))}
              <a href={GEO_LINK} target="_system">
                <b>Get Direction</b>
              </a>
            </FooterCardContent>
          </FooterCardIconContent>
          <FooterCardIconContent>
            <FooterCardIcon src={PhoneIcon} alt="" />
            <FooterCardContent>
              <span>
                Phone: <b>{CONTACT.phone}</b>
              </span>
              <span>
                Fax: <b>{CONTACT.fax}</b>
              </span>
            </FooterCardContent>
          </FooterCardIconContent>
          <FooterCardIconContent>
            <FooterCardIcon src={HoursIcon} alt="" />
            <FooterCardContent>
              <span>
                <b>Hours of Operation:</b>
              </span>
              {CONTACT.hours.map((hourLine) => (
                <span>{hourLine}</span>
              ))}
            </FooterCardContent>
          </FooterCardIconContent>
        </FooterCard>
        <hr />
        <FooterCard>
          <FooterCardTitle>Quick Navigation</FooterCardTitle>
          <FooterCardContent>
            <FooterNavLink to={"/"}>
              Home
              <img src={ExternalLinkIcon} alt="" height="20px" />
            </FooterNavLink>
            <FooterNavLink to={PHARMACY_CONTACT}>
              Pharmacy Contact
              <img src={ExternalLinkIcon} alt="" height="20px" />
            </FooterNavLink>
            <FooterNavLink to={PATIENTS_PAGE_LINKS.TRANSFER_PRESCRIPTION}>
              Transfer Prescription
              <img src={ExternalLinkIcon} alt="" height="20px" />
            </FooterNavLink>
            <FooterNavLink to={SPECIALTIES_PAGE_LINKS.main}>
              Specialties
              <img src={ExternalLinkIcon} alt="" height="20px" />
            </FooterNavLink>
            <FooterNavLink to={PROVIDERS_PAGE_LINKS.REFERRAL_FORM}>
              Referral Forms
              <img src={ExternalLinkIcon} alt="" height="20px" />
            </FooterNavLink>
          </FooterCardContent>
        </FooterCard>
        <hr />
        <FooterCard>
          <FooterCardTitle>
            <LogoButton style={{ width: "20vw" }} />
          </FooterCardTitle>
          <FooterCardContent>
            <span>{COPYRIGHT}</span>
            <span>All Rights Reserved.</span>
            <span>
              <SocialMediaIconLink
                to={SOCIAL_MEDIA_LINKS.facebook}
                target="_system"
              >
                <img src={FacebookIcon} alt="" />
              </SocialMediaIconLink>
              <SocialMediaIconLink
                to={SOCIAL_MEDIA_LINKS.instagram}
                target="_system"
              >
                <img src={InstagramIcon} alt="" />
              </SocialMediaIconLink>
              <SocialMediaIconLink
                to={SOCIAL_MEDIA_LINKS.twitter}
                target="_system"
              >
                <img src={TwitterIcon} alt="" />
              </SocialMediaIconLink>
            </span>
          </FooterCardContent>
          <FooterCardContent></FooterCardContent>
        </FooterCard>
      </FooterInnerContainer>
    </FooterOuterContainer>
  );
}
