import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function ErrorPage() {
  const [secondRemaining, setSecondRemaining] = useState(5);
  const navigate = useNavigate();
  useEffect(() => {
    const tickTick = setInterval(() => {
      if (secondRemaining === 0) {
        clearInterval(tickTick);
        navigate("/");
        return;
      }
      setSecondRemaining((prev) => prev - 1);
    }, 1000);
    return () => clearTimeout(tickTick);
  }, [secondRemaining, navigate]);

  return (
    <PageNotFoundContainer>
      <h1>Oops! Page Not Found</h1>
      <h3>Or may be you are trying to access broken Link</h3>
      <p>
        You will be redirected to the home page in{" "}
        <strong>{secondRemaining}</strong> seconds.
      </p>
      <strong>
        <Link to="/">Click Here</Link> for back to home
      </strong>
    </PageNotFoundContainer>
  );
}

const PageNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;
