import React from "react";
import data from "../data/drugs/merged_data_4.json";
import { setDocToFirestore } from "../service/firestore";

export default function Admin() {
  const [loading, setLoading] = React.useState(false);

  const handleAdminTask = () => {
    setLoading(true);
    const parsedData = JSON.parse(JSON.stringify(data));
    // setDocToFirestore("drugs", "0002-0152", parsedData["0002-0152"]);
    for (const key in parsedData) {
      setTimeout(() => {
        setDocToFirestore("drugs", key, parsedData[key])
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }, 1);
      // setDocToFirestore("drugs", key, parsedData[key])
      //   .then(() => {
      //     console.log("Document successfully written!");
      //   })
      //   .catch((error) => {
      //     console.error("Error writing document: ", error);
      //   });
    }
    setLoading(false);
  };

  return (
    <div>
      <h1>Admin</h1>
      <button onClick={handleAdminTask} disabled={loading}>
        Admin
      </button>
    </div>
  );
}
