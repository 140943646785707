import React from "react";

export default function Oncology() {
  return (
    <div>
      <h1>Oncology</h1>
      <p>This is the Oncology page.</p>
    </div>
  );
}
