import React from "react";
import styled from "styled-components";
import LogoButton from "../../atoms/Buttons/LogoButton";
import PatientPortalButton from "../../atoms/Buttons/PatientPortalButton";
import NavBarItem from "./NavBarItem";

import { NavBarData } from "../../content/navBarData";
import { HEADER_HEIGHT_MIN, Z_INDEX } from "../../theme/variables";
import PopupNavbar from "./PopupNavbar";
export default function NavBar() {
  return (
    <NavBarContainerOuter>
      <LongNavBar>
        <LogoButton />
        <NavBarItems>
          {NavBarData.map((item) => {
            return <NavBarItem key={item.key} item={item} />;
          })}
        </NavBarItems>
        <PatientPortalButton />
      </LongNavBar>
      <PopupNavbar />
    </NavBarContainerOuter>
  );
}

const NavBarContainerOuter = styled.div`
  width: 100%;
  padding: 0 20px;
  position: sticky;
  display: flex;
  align-items: center;
  height: ${HEADER_HEIGHT_MIN};
  top: 0;
  z-index: ${Z_INDEX.HEADER};
  background: #ffffff99;
  box-shadow: 0 10px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  @media screen {
    @media (max-width: 1024px) {
      height: ${HEADER_HEIGHT_MIN};
      top: 0;
    }
  }
`;

const NavBarItems = styled.div`
  display: flex;
  align-items: center;
`;

const LongNavBar = styled.div`
  height: ${HEADER_HEIGHT_MIN};
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
