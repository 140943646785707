import { getSearchQuery } from "./helper";

export const STEPS = {
  patient: "patient",
  transferRx: "transferRx",
  pharmacy: "pharmacy",
  doctor: "doctor",
  medicine: "medicine",
  summary: "summary",
  show_to_your_doctor: "show_to_your_doctor",
  show_to_your_pharmacy: "show_to_your_pharmacy",
};

export const defaultFormValues = {
  [STEPS.patient]: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    gender: "",
    allergies: "",
  },
  [STEPS.transferRx]: {
    transferFrom: "",
  },
  [STEPS.pharmacy]: {
    enterManually: false,
    placeDetails: {},
    pharmacyGMap: {
      name: "",
      formatted_address: "",
    },
    pharmacyName: "",
    pharmacyAddress: "",
    pharmacyPhone: "",
  },
  [STEPS.doctor]: { doctorName: "", doctorPhone: "" },
  [STEPS.medicine]: { transferAll: false, medicineList: [] },
  [STEPS.summary]: { agreeToTermsAndConditions: false },
};

export const STEP_META_DATA = {
  [STEPS.patient]: {
    step: {
      total: 4,
      current: 1,
    },
    title: "Who are you?",
    description:
      "Please enter your name, phone number, email, date of birth and gender.",
    goBack: {
      text: "Home",
      linkTo: "/",
    },
    goNext: {
      text: "Next",
      linkTo: getSearchQuery({ step: STEPS.transferRx }),
    },
  },
  [STEPS.transferRx]: {
    step: {
      total: 4,
      current: 2,
    },
    title: "From where would you like to transfer your prescription?",
    description:
      "Please select the option from where you would like to transfer your prescription.",
    stepOptions: [
      {
        value: STEPS.pharmacy,
        title: "Transfer from another pharmacy",
        description:
          "We will contact your previous pharmacy and transfer your prescription to us.",
        image:
          "https://ideogram.ai/api/images/direct/5JOV2cGaRzmrRBm15cVRPg.png",
        linkTo: getSearchQuery({ step: STEPS.pharmacy }),
        state: { from: STEPS.transferRx },
      },
      {
        value: STEPS.doctor,
        title: "Get from my doctor",
        description: "We will contact your doctor and get your prescriptions.",
        image:
          "https://ideogram.ai/api/images/direct/5JOV2cGaRzmrRBm15cVRPg.png",
        linkTo: getSearchQuery({ step: STEPS.doctor }),
        state: { from: STEPS.transferRx },
      },
    ],
    goBack: {
      text: "Back",
      linkTo: getSearchQuery({ step: STEPS.patient }),
    },
    goNext: {
      text: "Next",
      linkTo: getSearchQuery({ step: STEPS.pharmacy }),
    },
  },
  [STEPS.pharmacy]: {
    step: {
      total: 4,
      current: 3,
    },
    title: "Which pharmacy should we get your prescriptions from?",
    description:
      "We'll contact your pharmacy on your behalf, to get your prescriptions.",
    goBack: {
      text: "Back",
      linkTo: getSearchQuery({ step: STEPS.transferRx }),
    },
    goNext: {
      text: "Next",
      linkTo: getSearchQuery({ step: STEPS.medicine }),
    },
  },
  [STEPS.doctor]: {
    step: {
      total: 4,
      current: 3,
    },
    title: "Which doctor should we get your prescriptions from?",
    description:
      "We'll contact your doctor on your behalf, to get your prescriptions.",
    goBack: {
      text: "Back",
      linkTo: getSearchQuery({ step: STEPS.transferRx }),
    },
    goNext: {
      text: "Next",
      linkTo: getSearchQuery({ step: STEPS.medicine }),
    },
  },
  [STEPS.medicine]: {
    step: {
      total: 4,
      current: 4,
    },
    title: "What medicines do you need?",
    description:
      "Please select the medicines you need or You can also transfer all your prescriptions.",
    goBack: {
      text: "Back",
      linkTo: getSearchQuery({ step: STEPS.transferRx }),
    },
    goNext: {
      text: "Review",
      linkTo: getSearchQuery({ step: STEPS.summary }),
    },
  },
  [STEPS.summary]: {
    step: {
      total: 4,
      current: 4,
    },
    title: "Summary",
    description:
      "We will contact your previous pharmacy and transfer your prescription to us.",
    goBack: {
      text: "Back",
      linkTo: getSearchQuery({ step: STEPS.medicine }),
    },
    goNext: {
      text: "Send Transfer Request",
      linkTo: "/",
    },
  },
};
