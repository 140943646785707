import React from "react";
import { BannerDescription, BannerOuterContainer, BannerTitle } from "./styles";
import theme from "../../theme/theme";
import CONTACT from "../../content/contact";

export default function Banner() {
  return (
    <BannerOuterContainer>
      <BannerTitle>
        Specialized Pharmacy Services.{" "}
        <span style={{ color: theme.primary.main }}>
          Realized healthcare solutions.
        </span>
      </BannerTitle>
      <BannerDescription>
        At {CONTACT.name}, we provide specialized pharmacy services to patients
        with complex medical conditions.
        <br />
        We offer personalized care and support to help you manage your health.
        Our team of pharmacists and technicians are here to help you with your
        medications and answer any questions you may have.
        <br />
        We are committed to providing you with the best possible care and
        service.
      </BannerDescription>
    </BannerOuterContainer>
  );
}
