export function getTextColor(hexColor) {
  // Remove the '#' symbol if present
  if (!hexColor) return "#000000";
  hexColor = hexColor?.replace("#", "");
  // Convert the hex color to RGB
  const red = parseInt(hexColor.substr(0, 2), 16);
  const green = parseInt(hexColor.substr(2, 2), 16);
  const blue = parseInt(hexColor.substr(4, 2), 16);

  // Calculate the relative luminance of the color
  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

  // Return white if the luminance is below a threshold, otherwise return black
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}
