import axios from "axios";
import {
  API_SEND_NOTIFICATION,
  API_SEND_NOTIFICATION_TO_EMAIL,
  API_SET_FCM_TOKEN,
} from "./apis";
import { EMPTY_ARRAY, EMPTY_FUNCTION, EMPTY_STRING } from "../theme/variables";

// export const toastNotification = ({ title, description, status }) => {
//   // Implement your toast notification logic
//   window.alert(`Toast Notification: ${title} - ${description} - ${status}`);
//   console.log(`Toast Notification: ${title} - ${description} - ${status}`);
// };

// export const sendNativeNotification = ({ title, body }) => {
//   // Implement your native notification logic
//   console.log(`Native Notification: ${title} - ${body}`);
// };

export const setNewTokenFn = ({ token, uid }) =>
  axios({
    method: "post",
    url: API_SET_FCM_TOKEN,
    data: {
      token,
      uid,
    },
  })
    .then((res) => res)
    .catch((err) => console.error(err));

export const sendNotificationToEmailFn = ({
  title = "University Specialty Pharmacy",
  body = "XXXXXXXXX body",
  email = "info@universitynyc.com",
  badgeUrl = EMPTY_STRING,
  iconUrl = "https://universitynyc.com/favicon.ico",
  imageUrl = EMPTY_STRING,
  onClickLinkTo = EMPTY_FUNCTION,
  onSuccess = EMPTY_FUNCTION,
  onError = EMPTY_FUNCTION,
  onFinally = EMPTY_FUNCTION,
}) =>
  axios({
    method: "post",
    url: API_SEND_NOTIFICATION_TO_EMAIL,
    data: {
      notification: {
        title,
        body,
      },
      email,
      android: {
        notification: {
          imageUrl: iconUrl,
          image: iconUrl,
        },
      },
      apns: {
        payload: {
          aps: {
            category: onClickLinkTo,
          },
        },
        fcm_options: {
          image: iconUrl,
        },
      },
      webpush: {
        notification: {
          badge: badgeUrl,
          icon: iconUrl,
          image: imageUrl,
        },
        fcmOptions: {
          link: onClickLinkTo,
        },
      },
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      console.log("response", response);
      onSuccess();
    })
    .catch((error) => {
      console.log("error", error);
      onError();
    })
    .finally(() => onFinally());

export const sendNotificationFn = ({
  title = "University Specialty Pharmacy",
  body = "XXXXXXXXX body",
  tokens = EMPTY_ARRAY,
  imageUrl = EMPTY_STRING,
  onClickLinkTo = EMPTY_STRING,
  onSuccess = EMPTY_FUNCTION,
  onError = EMPTY_FUNCTION,
  onFinally = EMPTY_FUNCTION,
}) =>
  axios({
    method: "post",
    url: API_SEND_NOTIFICATION,
    data: {
      notification: {
        title,
        body,
      },
      tokens,
      android: {
        notification: {
          imageUrl: imageUrl,
        },
      },
      apns: {
        payload: {
          aps: {
            category: onClickLinkTo,
          },
        },
        fcm_options: {
          image: imageUrl,
        },
      },
      webpush: {
        notification: {
          badge: "https://universitynyc.com/favicon.ico",
          // icon: "https://universitynyc.com/favicon.ico",
          // image:
          //   "https://universitynyc.com/static/media/universityLogoNoBg.7a094b139fc8acf21b6d.png",
        },
        fcmOptions: {
          link: onClickLinkTo,
        },
      },
    },
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      console.log("response", response);
      onSuccess();
    })
    .catch((error) => {
      console.log("error", error);
      onError();
    })
    .finally(() => onFinally());
