import React from "react";
import { Autocomplete, Grid, Typography, debounce } from "@mui/material";
import TextInput from "./TextInput";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

export default function PharmacyAutoComplete({
  placeService,
  handleReset,
  id,
  name,
  label,
  error,
  helperText,
  value,
  onChange: setValue,
}) {
  // const [searchInput, setSearchInput] = React.useState("");
  const [searchResults, setSearchResults] = React.useState({
    places: [],
    status: "",
    loading: false,
  });

  // function getLatLngByZipcode(zipcode) {
  //   var geocoder = new google.maps.Geocoder();
  //   var address = zipcode;
  //   geocoder.geocode({ address: address }, function (results, status) {
  //     if (status == google.maps.GeocoderStatus.OK) {
  //       var latitude = results[0].geometry.location.lat();
  //       var longitude = results[0].geometry.location.lng();
  //       alert("Latitude: " + latitude + "\nLongitude: " + longitude);
  //     } else {
  //       alert("Request failed.");
  //     }
  //   });
  //   return [latitude, longitude];
  // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGMapResults = React.useCallback(
    debounce((query) => {
      placeService.textSearch(
        {
          query,
          region: "usa",
          type: "pharmacy",
          // language: "",
          // location: "", //lang lat
          // radius: "", //in meters
        },
        (places, status) => {
          if (status === "OK") {
            const resultSliced = places;
            setSearchResults({ places: resultSliced, status, loading: false });
          } else {
            setSearchResults({ places: [], status, loading: false });
          }
        }
      );
    }, 300),
    []
  );

  const getOptionLabel = (option) => {
    if (typeof option === "string") return option;
    if (option?.name) {
      if (!option?.enterManually && option?.formatted_address)
        return `${option.name}, ${option.formatted_address}`;
      return option.name;
    }
    return "";
  };

  return (
    <Autocomplete
      loading={searchResults.loading}
      loadingText="Loading..."
      value={value}
      onChange={(event, newValue, reason) => {
        if (reason === "clear") {
          handleReset();
          return;
        }
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        if (newInputValue === "") {
          setSearchResults({ places: [], status: "", loading: false });
          return;
        }
        setSearchResults({ places: [], status: "", loading: false });
        searchGMapResults(newInputValue);
      }}
      renderInput={(params) => {
        return (
          <TextInput
            {...params}
            id={id}
            name={name}
            label={label}
            placeholder="Search with pharmacy name"
            error={error}
            helperText={helperText}
          />
        );
      }}
      noOptionsText="No locations found"
      options={searchResults?.places}
      getOptionLabel={getOptionLabel}
      filterOptions={(options, params) => {
        const filtered = options; //filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value

        if (!searchResults.loading && inputValue !== "") {
          filtered.push({
            name: inputValue,
            formatted_address: "Use this address",
            enterManually: true,
          });
        }

        return filtered;
      }}
      renderOption={(props, option, params) => {
        const { inputValue } = params;
        const matchesName = match(option.name, inputValue, {
          insideWords: true,
        });
        const partsName = parse(option.name, matchesName);
        const matchesAddress = match(option.formatted_address, inputValue, {
          insideWords: true,
        });
        const partsAddress = parse(option.formatted_address, matchesAddress);
        return (
          <li {...props} key={props.key}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <img
                  src={
                    option?.icon ||
                    "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png"
                  }
                  alt="icon"
                  width="34"
                  height="34"
                />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {partsName.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                {/* {option.name} */}
                <Typography variant="body2" color="text.secondary">
                  {partsAddress.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
