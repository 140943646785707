import styled from "styled-components";

export const BannerOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 5rem;
  gap: 3rem;
  @media screen and (max-width: 560px) {
    padding: 2rem;
    gap: 2rem;
  }
`;

export const BannerTitle = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  margin: 0;
  @media screen and (max-width: 560px) {
    font-size: 2rem;
  }
`;

export const BannerInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: clamp(330px, 90%, 1200px);
  @media screen and (max-width: 560px) {
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }
`;

export const BannerImage = styled.div`
  width: 60%;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;

export const BannerContent = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  line-height: 1.7rem;
  word-break: keep-all;
  ul {
    margin: 0;
  }
  h3 {
    background-image: ${({ markerUrl }) => `url(${markerUrl})`};
    background-size: 1.2rem;
    background-position: 0px 50%;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    padding-left: 1.7rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
  p {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
  }
  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;
