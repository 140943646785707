import React from "react";
import { LeftOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { render } from "@react-email/render";

import theme from "../../../../theme/theme";
import AsyncButton from "../../../../atoms/Buttons/AsyncButton";
import { sendTransferRxMailFn } from "../../../../service/mailService";
import { sendNotificationToEmailFn } from "../../../../service/notificationService";

import { STEPS, STEP_META_DATA } from "./utils";
import * as StepSC from "./StepComponent";
import HtmlMailSummary, { EmailBody } from "./HtmlMailSummary";
import { addToFirestore } from "../../../../service/firestore";
import CONTACT from "../../../../content/contact";
import { PHARMACY_CONTACT } from "../../../../content/pageLinks";

const Summary = ({ ptTransferData, setStepData }) => {
  const navigate = useNavigate();
  const [error, setError] = React.useState("");

  const stepMetaData = STEP_META_DATA[STEPS.summary];
  const [sendMailLoading, setSendMailLoading] = React.useState(false);
  const summaryInfo = ptTransferData[STEPS.summary];
  const setSummaryInfo = setStepData(STEPS.summary);

  const htmlTransferRxSummary = render(
    <HtmlMailSummary ptTransferData={ptTransferData} />
  );

  const EditButton = ({ linkTo, name }) => {
    // const stepMetaData = STEP_META_DATA[linkTo];
    return (
      <StepSC.LinkTextButton onClick={() => navigate(linkTo)}>
        <EditOutlined /> <span>{name}</span>
      </StepSC.LinkTextButton>
    );
  };

  const handleSendTransferRx = (tryLeft) => {
    setSendMailLoading(true);
    console.log("mail", summaryInfo.agreeToTermsAndConditions);
    if (!summaryInfo.agreeToTermsAndConditions) {
      setError("Please indicate that you are agree to the above agreement");
      setSendMailLoading(false);
      return;
    }
    console.log("mail", tryLeft);
    sendTransferRxMailFn({
      // from: "Transfer RX USP <info@universitynyc.com>",
      to: `info@universitynyc.com,${ptTransferData?.[STEPS.patient]?.email}`,
      subject: "Transfer Request",
      message: htmlTransferRxSummary,
      onSuccess: () => {
        addToFirestore("transferRx", ptTransferData)
          .then((docId) => {
            sendNotificationToEmailFn({
              title: `New Transfer Request ${docId}`,
              body: `TransferRx from ${
                ptTransferData?.[STEPS.patient]?.firstName
              } - ${ptTransferData?.[STEPS.patient]?.lastName} ${
                ptTransferData?.[STEPS.patient]?.phone
              }`,
              iconUrl:
                "https://ideogram.ai/api/images/direct/5JOV2cGaRzmrRBm15cVRPg.png",
              onClickLinkTo: "/transfer-rx",
            });
          })
          .finally(() => {
            setSendMailLoading(false);
            navigate(stepMetaData.goNext.linkTo);
          });
      },
      onError: () => {
        console.log("error");
        if (tryLeft > 0) {
          setTimeout(() => {
            handleSendTransferRx(tryLeft - 1);
          }, 1000);
        } else {
          setSendMailLoading(false);
        }
      },
    });
  };

  return (
    <StepSC.OuterContainer>
      <StepSC.NavBar>
        <StepSC.LinkTextButton to={stepMetaData.goBack.linkTo}>
          <LeftOutlined /> <span>{stepMetaData.goBack.text}</span>
        </StepSC.LinkTextButton>
        <StepSC.Counter></StepSC.Counter>
      </StepSC.NavBar>
      <EmailBody ptTransferData={ptTransferData} EditButton={EditButton}>
        <h4>
          I authorize {CONTACT.name} to contact the transferring pharmacy,
          doctor office, and myself.*
          <br />
          <input
            type="checkbox"
            value={summaryInfo.agreeToTermsAndConditions}
            onChange={(e) => {
              console.log(e.target.checked);
              console.log(summaryInfo);
              setSummaryInfo({
                agreeToTermsAndConditions: e.target.checked,
              });
              if (e.target.checked) setError("");
            }}
            required
          />
          <label for="scales">Yes</label>
        </h4>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <StepSC.ActionItems style={{ marginTop: "20px" }}>
          <AsyncButton
            type="submit"
            onClick={() => handleSendTransferRx(5)}
            loading={sendMailLoading}
            color={theme.primary.main}
          >
            {stepMetaData.goNext.text}
          </AsyncButton>
          <StepSC.LinkTextButton to={PHARMACY_CONTACT} target="_blank">
            <span>Show pharmacy Info</span>
          </StepSC.LinkTextButton>
          <div style={{ flexGrow: 1 }} />
        </StepSC.ActionItems>
      </EmailBody>
    </StepSC.OuterContainer>
  );
};

export default Summary;
