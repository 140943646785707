import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { LeftOutlined, CloseOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";

import MedicineAutoComplete from "../../../../atoms/Inputs/MedicationAutoComplete";
import CheckboxInput from "../../../../atoms/Inputs/CheckboxInput";

import { STEPS, STEP_META_DATA } from "./utils";
import * as StepSC from "./StepComponent";
import theme from "../../../../theme/theme";

export default function Medicine({ ptTransferData, setStepData }) {
  const navigate = useNavigate();
  const stepMetaData = STEP_META_DATA[STEPS.medicine];
  const medicineInfo = ptTransferData[STEPS.medicine];
  const setMedicineInfo = setStepData(STEPS.medicine);

  const formik = useFormik({
    initialValues: { ...medicineInfo },
    validationSchema: yup.object({
      transferAll: yup.boolean(),
      medicineList: yup.array().when("transferAll", {
        is: false,
        then: () =>
          yup
            .array()
            .of(yup.string())
            .min(
              1,
              "Please select medicine you want to transfer or Select transfer all"
            )
            .required("Please select a medicine or Select transfer all"),
      }),
    }),
    onSubmit: (values) => {
      setMedicineInfo(values);
      navigate(stepMetaData.goNext.linkTo, {
        state: { from: STEPS.medicine },
      });
    },
  });

  const onChangeMedicineAutoComplete = (values) => {
    // console.log(values);
    formik.setFieldValue("medicineList", values);
  };

  const handleRemoveSelected = (medicine) => {
    formik.setFieldValue(
      "medicineList",
      formik.values.medicineList.filter((value) => value !== medicine)
    );
  };

  return (
    <StepSC.OuterContainer>
      <StepSC.NavBar>
        <StepSC.LinkTextButton to={stepMetaData.goBack.linkTo}>
          <LeftOutlined /> <span>{stepMetaData.goBack.text}</span>
        </StepSC.LinkTextButton>
        <StepSC.Counter>
          {stepMetaData.step.current} of {stepMetaData.step.total}
        </StepSC.Counter>
      </StepSC.NavBar>
      <StepSC.Hero>
        <StepSC.Title>{stepMetaData.title}</StepSC.Title>
        <StepSC.Description>{stepMetaData.description}</StepSC.Description>
        <StepSC.Form onSubmit={formik.handleSubmit}>
          <StepSC.ActionLongItem>
            <CheckboxInput
              id="transferAll"
              name="transferAll"
              checked={formik.values.transferAll}
              onChange={(e) =>
                formik.setFieldValue("transferAll", e.target.checked)
              }
            />
            Transfer all prescriptions
          </StepSC.ActionLongItem>
          {!formik.values.transferAll && (
            <MedicationContainerSC>
              <MedicineAutoComplete
                id="medicineList"
                name="medicineList"
                label="Medicine"
                value={formik.values.medicineList}
                onChange={onChangeMedicineAutoComplete}
                error={
                  formik.touched.medicineList &&
                  Boolean(formik.errors.medicineList)
                }
                helperText={
                  formik.touched.medicineList && formik.errors.medicineList
                }
              />
              <SelectedMedicineWrapper>
                {formik.values.medicineList.map((value) => (
                  <SelectedMedicine key={value}>
                    {value}
                    <CloseOutlined
                      onClick={() => handleRemoveSelected(value)}
                    />
                  </SelectedMedicine>
                ))}
              </SelectedMedicineWrapper>
            </MedicationContainerSC>
          )}
          <StepSC.LinkButton onClick={formik.handleSubmit}>
            {stepMetaData.goNext.text}
          </StepSC.LinkButton>
        </StepSC.Form>
      </StepSC.Hero>
    </StepSC.OuterContainer>
  );
}

const MedicationContainerSC = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SelectedMedicineWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const SelectedMedicine = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 15px;
  background-color: ${theme.secondary.light};
  border-radius: 5px;
  & > svg {
    cursor: pointer;
  }
`;
