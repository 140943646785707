import React from "react";
import { Link } from "react-router-dom";
import { ExportOutlined } from "@ant-design/icons";

import { CONTACT_HTML } from "../../content/contact";
import { PATIENTS_PAGE_LINKS } from "../../content/pageLinks";
import Banner from "../../atoms/Banners";
import { HomePageOuterContainer } from "./styles";
import CONTACT from "../../content/contact";
import FourColumnServices from "../../atoms/FourColumnServices";

const FourColumnServicesContent = [
  {
    title: "Prescriber",
    description: `${CONTACT.name} works with our Prescriber Partners to provide comprehensive clinical support and remove all administrative burdens so that your patients receive their prescribed medications in a timely and coordinated way.`,
    linkTo: "/providers",
    backgroundImage: "/static/images/usp-providers.jpg",
  },
  {
    title: "Pharmacy",
    description: `We are here to help you as your support team and advocates. We will work to guide you through your treatment and to remove all barriers to your receiving your medications.`,
    linkTo: "/pharmacy",
    backgroundImage: "/static/images/usp-patients.jpg",
  },
  {
    title: "Payors",
    description: `Our team supports payers and plan sponsors through an integrated care management approach.`,
    linkTo: "/payors",
    backgroundImage: "/static/images/usp-payors.jpg",
  },
  {
    title: "Manufacturers",
    description: `${CONTACT.name} provides a wide range of services to assist in the efficient and measurable distribution of specialty drugs and related therapies.`,
    linkTo: "/manufacturers",
    backgroundImage: "/static/images/usp-manufacturers.jpg",
  },
];
export default function HomePage() {
  return (
    <HomePageOuterContainer>
      <Banner />
      <FourColumnServices content={FourColumnServicesContent} />
      {/* <h1>Welcome to {CONTACT_HTML.name}</h1> */}
      <h3>
        For transfer your Prescription to our pharmacy, please click{" "}
        <Link
          to={PATIENTS_PAGE_LINKS.TRANSFER_PRESCRIPTION}
          style={{ color: "rgb(0,0,238)" }}
        >
          Transfer Rx
          <ExportOutlined />.
        </Link>
      </h3>
      <h4>
        For pharmacy contact information, please click here.{" "}
        <Link
          to={PATIENTS_PAGE_LINKS.PHARMACY_CONTACT}
          style={{ color: "rgb(0,0,238)" }}
        >
          Contact Us
        </Link>
      </h4>
      <p>
        We are a specialty pharmacy that provides services to patients with
        complex medical conditions. We offer personalized care and support to
        help you manage your health. Our team of pharmacists and technicians are
        here to help you with your medications and answer any questions you may
        have. We are committed to providing you with the best possible care and
        service. If you have any questions or need assistance, please contact us
        at <strong>{CONTACT_HTML.phone}</strong> or {CONTACT_HTML.email}.
        <br />
        We look forward to serving you.
      </p>
    </HomePageOuterContainer>
  );
}
