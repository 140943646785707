import React from "react";
import { PatientOverviewOuterContainer } from "./styled";
import ColorBanner from "../../../atoms/ColorBanner";
import theme from "../../../theme/theme";
export default function PatientOverView() {
  return (
    <PatientOverviewOuterContainer
      style={{ background: theme.secondary.light }}
    >
      <ColorBanner
        title="Ready to skip the trip to the pharmacy?"
        imgUrl={"/static/images/patientoverview.png"}
        markerUrl="https://img.icons8.com/?size=100&id=gw7xP6ZiA66C&format=png&color=000000"
      >
        <p>
          <b>Once you’re registered: </b>
        </p>
        <h3>Send us your prescription.</h3>
        <ul>
          <li>
            <p>
              <b>Current prescriptions:</b>
              Transfer eligible medications to home delivery with a few clicks.
            </p>
          </li>
          <li>
            <p>
              <b>New prescriptions: </b>
              Ask your doctor to <nobr>e-prescribe</nobr> or fax a{" "}
              <nobr>3-month</nobr> supply, or you can request a new prescription
              online.
            </p>
          </li>
        </ul>
        <h3>We’ll take care of it from there.</h3>
        <p>
          Our pharmacists will check that the medication prescribed is safe and
          effective for you. If we need more information, we’ll contact your
          doctor.
        </p>
        <h3>Then, your medication arrives at your door.</h3>
        <p>
          Your medication is shipped safely in discreet packaging. We offer free
          standard shipping and online order tracking.
        </p>
      </ColorBanner>
    </PatientOverviewOuterContainer>
  );
}
