import styled from "styled-components";
import { NavLink } from "react-router-dom";
import theme from "../../theme/theme";

export default function NavBarItem({ item }) {
  return (
    <NavBarItemSC>
      <NavBarItemTitleSC to={item?.pageLink}>{item.title}</NavBarItemTitleSC>
      {item?.children && (
        <NavBarDropdownSC>
          {item.children.map((subItem) => (
            <NavBarDropdownItemSC key={subItem.key} to={subItem.pageLink}>
              {subItem.title}
            </NavBarDropdownItemSC>
          ))}
        </NavBarDropdownSC>
      )}
    </NavBarItemSC>
  );
}

export const NavBarDropdownItemSC = styled(NavLink)`
  text-decoration: none;
  margin: 10px 20px;
  text-align: center;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  color: #343a40;
  box-sizing: border-box;
  &:hover {
    border-bottom: 2px solid #ef5821;
  }
`;

export const NavBarDropdownSC = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 100%;
  left: 0;
  padding: 0;
  max-height: 60vh;
  width: max-content;
  box-sizing: border-box;
  background-color: #ffe492;
  opacity: 0.95;
  border: 1px solid #ffc20f;
  border-radius: 10px;
  overflow-y: auto;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    z-index: -1;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffc20f;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: #ffbf0054;
    margin: 10px;
  }
`;

export const NavBarItemTitleSC = styled(NavLink)`
  text-decoration: none;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  color: #343a40;
  box-sizing: border-box;
  &:hover {
    border-bottom: 2px solid ${theme.primary.main};
  }
`;

export const NavBarItemSC = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 32px;
  &:hover ${NavBarDropdownSC} {
    display: flex;
  }
`;
