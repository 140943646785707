export const PATIENT_PORTAL = "/patient-portal";

export const AUTH_PAGE_LINKS = {
  main: PATIENT_PORTAL,
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
};

export const SPECIALTIES_PAGE_LINKS = {
  main: "/specialties",
  CARDIOLOGY: "/specialties/cardiology",
  DERMATOLOGY: "/specialties/dermatology",
  GASTROENTEROLOGY: "/specialties/gastroenterology",
  HIV: "/specialties/hiv",
  ONCOLOGY: "/specialties/oncology",
  OPHTHALMOLOGY: "/specialties/ophthalmology",
  PSYCHIATRY: "/specialties/psychiatry",
  PULMONOLOGY: "/specialties/pulmonology",
  RHEUMATOLOGY: "/specialties/rheumatology",
};

export const PATIENTS_PAGE_LINKS = {
  main: "/patients",
  ACCOUNT_SETUP: "/patients/account-setup",
  PATIENT_PORTAL: PATIENT_PORTAL,
  TRANSFER_PRESCRIPTION: "/patients/transfer-prescription",
  PHARMACY_CONTACT: "/patients/pharmacy-contact",
};

export const PHARMACY_CONTACT = PATIENTS_PAGE_LINKS.PHARMACY_CONTACT;

export const PROVIDERS_PAGE_LINKS = {
  main: "/providers",
  REFER_A_PATIENT: "/providers/refer-a-patient",
  PROVIDER_PORTAL_SETUP: "/providers/provider-portal-setup",
  PROVIDER_PORTAL: "/providers/provider-portal",
  REFERRAL_FORM: "/providers/referral-form",
  INFUSION_RESOURCES: "/providers/infusion-resources",
};

export const PHARMA_PAGE_LINKS = {
  main: "/pharma",
};
