import React from "react";

export default function Psychiatry() {
  return (
    <div>
      <h1>Psychiatry</h1>
      <p>This is the Psychiatry page.</p>
    </div>
  );
}
