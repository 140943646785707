export const FORM_TYPES = {
  infusion: "infusion",
  other: "other",
};

export const FORMS = {
  [FORM_TYPES.infusion]: [
    {
      name: "Gastroenterology",
      formLink: "/static/referralForms/Untitled_document.pdf",
    },
  ],
  [FORM_TYPES.other]: [
    {
      name: "Other",
      formLink: "/static/referralForms/Untitled_document.pdf",
    },
  ],
};
