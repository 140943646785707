import React from "react";
import { LeftOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
// import { useFormik } from "formik";
// import * as yup from "yup";

import { STEPS, STEP_META_DATA } from "./utils";
import * as StepSC from "./StepComponent";

export default function TransferRx({ ptTransferData, setStepData }) {
  const stepMetaData = STEP_META_DATA[STEPS.transferRx];
  const setTransferRxInfo = setStepData(STEPS.transferRx);

  return (
    <StepSC.OuterContainer>
      <StepSC.NavBar>
        <StepSC.LinkTextButton to={stepMetaData.goBack.linkTo}>
          <LeftOutlined /> <span>{stepMetaData.goBack.text}</span>
        </StepSC.LinkTextButton>
        <StepSC.Counter>
          {stepMetaData.step.current} of {stepMetaData.step.total}
        </StepSC.Counter>
      </StepSC.NavBar>
      <StepSC.Hero>
        <StepSC.Title>{stepMetaData.title}</StepSC.Title>
        <StepSC.Description>{stepMetaData.description}</StepSC.Description>
        <StepSC.ActionBlock>
          {stepMetaData.stepOptions.map((option, index) => (
            <StepSC.OptionCard
              key={index}
              to={option.linkTo}
              state={option.state}
              onClick={() => setTransferRxInfo({ transferFrom: option.value })}
            >
              <StepSC.OptionImage src={option.image} />
              <StepSC.OptionTitle>{option.title}</StepSC.OptionTitle>
              <StepSC.OptionDescription>
                {option.description}
              </StepSC.OptionDescription>
            </StepSC.OptionCard>
          ))}
        </StepSC.ActionBlock>
      </StepSC.Hero>
    </StepSC.OuterContainer>
  );
}
