import {
  PATIENTS_PAGE_LINKS,
  PROVIDERS_PAGE_LINKS,
  SPECIALTIES_PAGE_LINKS,
} from "./pageLinks";

export const NavBarData = [
  {
    title: "Specialties",
    key: "specialties",
    pageLink: SPECIALTIES_PAGE_LINKS.main,
    children: [
      {
        title: "Overview",
        key: "overview",
        pageLink: SPECIALTIES_PAGE_LINKS.main,
      },
      {
        title: "Cardiology",
        key: "cardiology",
        pageLink: SPECIALTIES_PAGE_LINKS.CARDIOLOGY,
      },
      {
        title: "Dermatology",
        key: "dermatology",
        pageLink: SPECIALTIES_PAGE_LINKS.DERMATOLOGY,
      },
      {
        title: "Gastroenterology",
        key: "gastroenterology",
        pageLink: SPECIALTIES_PAGE_LINKS.GASTROENTEROLOGY,
      },
      {
        title: "HIV",
        key: "hiv",
        pageLink: SPECIALTIES_PAGE_LINKS.HIV,
      },
      {
        title: "Oncology/Cancer",
        key: "oncology",
        pageLink: SPECIALTIES_PAGE_LINKS.ONCOLOGY,
      },
      {
        title: "Ophthalmology",
        key: "ophthalmology",
        pageLink: SPECIALTIES_PAGE_LINKS.OPHTHALMOLOGY,
      },
      {
        title: "Psychiatry",
        key: "psychiatry",
        pageLink: SPECIALTIES_PAGE_LINKS.PSYCHIATRY,
      },
      {
        title: "Pulmonology",
        key: "pulmonology",
        pageLink: SPECIALTIES_PAGE_LINKS.PULMONOLOGY,
      },
      {
        title: "Rheumatology",
        key: "rheumatology",
        pageLink: SPECIALTIES_PAGE_LINKS.RHEUMATOLOGY,
      },
    ],
  },
  {
    title: "Patients",
    key: "patients",
    pageLink: PATIENTS_PAGE_LINKS.main,
    children: [
      {
        title: "Overview",
        key: "overview",
        pageLink: PATIENTS_PAGE_LINKS.main,
      },
      {
        title: "Account Setup",
        key: "account-setup",
        pageLink: PATIENTS_PAGE_LINKS.ACCOUNT_SETUP,
      },
      {
        title: "Patient Portal",
        key: "patient-portal",
        pageLink: PATIENTS_PAGE_LINKS.PATIENT_PORTAL,
      },
      {
        title: "Transfer Prescription",
        key: "transfer-prescription",
        pageLink: PATIENTS_PAGE_LINKS.TRANSFER_PRESCRIPTION,
      },
      {
        title: "Pharmacy Contact",
        key: "pharmacy-contact",
        pageLink: PATIENTS_PAGE_LINKS.PHARMACY_CONTACT,
      },
    ],
  },
  {
    title: "Providers",
    key: "providers",
    pageLink: PROVIDERS_PAGE_LINKS.main,
    children: [
      {
        title: "Overview",
        key: "overview",
        pageLink: PROVIDERS_PAGE_LINKS.main,
      },
      {
        title: "Refer a Patient",
        key: "refer-a-patient",
        pageLink: PROVIDERS_PAGE_LINKS.REFER_A_PATIENT,
      },
      {
        title: "Provider Portal Setup",
        key: "provider-portal-setup",
        pageLink: PROVIDERS_PAGE_LINKS.PROVIDER_PORTAL_SETUP,
      },
      {
        title: "Provider Portal",
        key: "provider-portal",
        pageLink: PROVIDERS_PAGE_LINKS.PROVIDER_PORTAL,
      },
      {
        title: "Referral Forms",
        key: "referral-forms",
        pageLink: PROVIDERS_PAGE_LINKS.REFERRAL_FORM,
      },
      {
        title: "Infusion Resources",
        key: "infusion-resources",
        pageLink: PROVIDERS_PAGE_LINKS.INFUSION_RESOURCES,
      },
    ],
  },
  // {
  //   title: "Pharma",
  //   key: "pharma",
  //   pageLink: PHARMA_PAGE_LINKS.main,
  // },
  {
    title: "Insurance & Copay Assistance",
    key: "insurance-copay-assistance",
    children: [
      {
        title: "Overview",
        key: "overview",
        pageLink: "/insurance-copay-assistance",
      },
      {
        title: "Insurance",
        key: "insurance",
        pageLink: "/insurance",
      },
      {
        title: "Copay Assistance",
        key: "copay-assistance",
        pageLink: "/copay-assistance",
      },
      {
        title: "Know Your Insurance",
        key: "know-your-insurance",
        pageLink: "/know-your-insurance",
      },
    ],
  },
];
