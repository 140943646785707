import {
  serverTimestamp,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { fireStore } from "./firebase";

export function addToFirestore(collectionName, data) {
  return new Promise((resolve, reject) => {
    const collectionRef = collection(fireStore, collectionName);
    console.log("addToFirestore -> collectionRef", collectionRef);
    const stringData = JSON.stringify(data);
    const jsonData = JSON.parse(stringData);
    addDoc(collectionRef, { ...jsonData, createdAt: serverTimestamp() }).then(
      (docRef) => {
        console.log("Document written with ID: ", docRef.id);
        resolve(docRef.id);
      },
      (error) => {
        console.error("Error adding document: ", error);
        reject(error);
      }
    );
  });
}

export function setDocToFirestore(collectionName, docId, data) {
  return new Promise((resolve, reject) => {
    const docRef = doc(fireStore, collectionName, docId);
    const stringData = JSON.stringify(data);
    const jsonData = JSON.parse(stringData);
    setDoc(docRef, { ...jsonData, createdAt: serverTimestamp() }).then(
      () => {
        console.log("Document written with ID: ", docId);
        resolve();
      },
      (error) => {
        console.error("Error adding document: ", error);
        reject(error);
      }
    );
  });
}

export function getCollectionFromFirestore(collectionName) {
  return new Promise((resolve, reject) => {
    const collectionRef = collection(fireStore, collectionName);
    getDocs(collectionRef)
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), docId: doc.id });
        });
        resolve(data);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        reject(error);
      });
  });
}

export function getDocumentByIdFromFirestore(collectionName, docId) {
  return new Promise((resolve, reject) => {
    const docRef = doc(fireStore, collectionName, docId);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        resolve({ ...docSnap.data(), docId });
      } else {
        console.log("No such document!");
        reject("No such document!");
      }
    });
  });
}

export function updateDocumentInFirestore(collectionName, docId, data) {
  return new Promise((resolve, reject) => {
    const docRef = doc(fireStore, collectionName, docId);
    updateDoc(docRef, { ...data, updatedAt: serverTimestamp() })
      .then(() => {
        console.log("Document successfully updated!");
        resolve();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        reject(error);
      });
  });
}

export function deleteDocumentInFirestore(collectionName, docId) {
  return new Promise((resolve, reject) => {
    const docRef = doc(fireStore, collectionName, docId);
    deleteDoc(docRef)
      .then(() => {
        console.log("Document successfully deleted!");
        resolve();
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
        reject(error);
      });
  });
}

export function getDocumentFromFirestoreWithQuery(
  collectionName,
  field,
  comparison,
  value
) {
  return new Promise((resolve, reject) => {
    const collectionRef = collection(fireStore, collectionName);
    const query = collectionRef.where(field, comparison, value);
    getDocs(query)
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), docId: doc.id });
        });
        resolve(data);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        reject(error);
      });
  });
}
