import styled from "styled-components";
import theme from "../../theme/theme";
import { Link } from "react-router-dom";

export const FooterOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.secondary.light};
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 10%;
  padding: 10px;
`;

export const FooterInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    gap: 0;
  }
`;

export const FooterCard = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
`;

export const FooterCardTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const FooterCardContent = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
`;

export const FooterCardIcon = styled.img`
  grid-area: icon;
  align-items: start;
  width: 100%;
  object-fit: contain;
`;

export const FooterCardIconContent = styled.div`
  display: grid;
  grid-template: "icon content";
  grid-template-columns: 2rem 1fr;
  align-items: start;
  gap: 1rem;
  padding: 1rem;
`;

export const FooterNavLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.blue.main};
  padding-bottom: 1rem;
  font-weight: 500;
  & > img {
    max-height: fit-content;
    margin-bottom: 0;
    padding: 0;
  }
`;

export const SocialMediaIconLink = styled(Link)`
  & > img {
    height: 30px;
    margin: 10px;
    margin-left: 0;
  }
`;
