import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import InputMask from "react-input-mask";
import isEmailValidator from "validator/lib/isEmail";

import TextInput from "../../../../atoms/Inputs/TextInput";

import * as StepSC from "./StepComponent";
import { STEPS, STEP_META_DATA } from "./utils";
import { MenuItem } from "@mui/material";

export default function Patient({ ptTransferData, setStepData }) {
  const navigate = useNavigate();

  const stepMetaData = STEP_META_DATA[STEPS.patient];
  const patientInfo = ptTransferData[STEPS.patient];
  const setPatientInfo = setStepData(STEPS.patient);

  const formik = useFormik({
    initialValues: { ...patientInfo },
    validationSchema: yup.object({
      firstName: yup.string().required("Your first name is required"),
      lastName: yup.string().required("Your last name is required"),
      phone: yup
        .string()
        .test(
          "format",
          "Invalid phone format",
          (value) => value.replace(/-|_/g, "").length === 10
        )
        .required("Your phone number is required"),
      email: yup
        .string()
        // .email("Invalid email format")
        .test(
          "format",
          "Invalid email format",
          (value) => !value || isEmailValidator(value)
        ),
      gender: yup.string().required("Your gender is required"),
      dob: yup
        .date()
        .required("Your birth date is required")
        .max(new Date(), "You can't choose future date"),
      allergies: "",
    }),
    onSubmit: (values) => {
      setPatientInfo(values);
      navigate(stepMetaData.goNext.linkTo);
    },
  });

  return (
    <StepSC.OuterContainer>
      <StepSC.NavBar>
        <StepSC.LinkTextButton to={stepMetaData.goBack.linkTo}>
          <LeftOutlined /> <span>{stepMetaData.goBack.text}</span>
        </StepSC.LinkTextButton>
        <StepSC.Counter>
          {stepMetaData.step.current} of {stepMetaData.step.total}
        </StepSC.Counter>
      </StepSC.NavBar>
      <StepSC.Hero>
        <StepSC.Title>{stepMetaData.title}</StepSC.Title>
        <StepSC.Description>{stepMetaData.description}</StepSC.Description>
        <StepSC.Form onSubmit={formik.handleSubmit}>
          <StepSC.ActionItems>
            <TextInput
              label="First Name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.firstName && formik.errors.firstName}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextInput
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && formik.errors.lastName}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </StepSC.ActionItems>
          <InputMask
            label="Phone"
            name="phone"
            mask="999-999-9999"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {(inputProps) => (
              <TextInput
                {...inputProps}
                type="tel"
                disableUnderline
                error={formik.touched.phone && formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            )}
          </InputMask>
          <TextInput
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
            helperText={
              (formik.touched.email && formik.errors.email) ||
              "To receive a copy of your transfer request"
            }
          />
          <StepSC.ActionItems>
            <TextInput
              label="Date of Birth"
              name="dob"
              type="date"
              value={formik.values.dob}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.dob && formik.errors.dob}
              helperText={formik.touched.dob && formik.errors.dob}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: {
                  max: new Date().toISOString().split("T")[0],
                },
              }}
            />
            <TextInput
              select
              label="Gender"
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.gender && formik.errors.gender}
              helperText={formik.touched.gender && formik.errors.gender}
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Prefer not to disclose">
                Prefer not to disclose
              </MenuItem>
            </TextInput>
          </StepSC.ActionItems>

          <StepSC.LinkButton onClick={formik.handleSubmit}>
            {stepMetaData.goNext.text}
          </StepSC.LinkButton>
        </StepSC.Form>
      </StepSC.Hero>
    </StepSC.OuterContainer>
  );
}
