import React from "react";

export default function Pulmonology() {
  return (
    <div>
      <h1>Pulmonology</h1>
      <p>This is the Pulmonology page.</p>
    </div>
  );
}
