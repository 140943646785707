import React from "react";
import { LeftOutlined, ExportOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import PharmacyAutoComplete from "../../../../atoms/Inputs/PharmacyAutoComplete";

import * as StepSC from "./StepComponent";
import { STEPS, STEP_META_DATA, defaultFormValues } from "./utils";
import TextInput from "../../../../atoms/Inputs/TextInput";
import { getPlaceDetails } from "./helper";

export default function Pharmacy({ ptTransferData, setStepData }) {
  const navigate = useNavigate();

  const stepMetaData = STEP_META_DATA[STEPS.pharmacy];
  const pharmacyInfo = ptTransferData[STEPS.pharmacy];
  const setPharmacyInfo = setStepData(STEPS.pharmacy);

  const google = window.google;
  const placeService = new google.maps.places.PlacesService(
    document.createElement("div")
  );

  const formik = useFormik({
    initialValues: pharmacyInfo,
    validationSchema: yup.object({
      enterManually: yup.boolean().required(),
      pharmacyName: yup.string().required("Please enter a pharmacy name"),
      pharmacyAddress: yup.string().required("Please enter a pharmacy address"),
      pharmacyPhone: yup.string().required("Please enter a pharmacy phone"),
      pharmacyGMap: yup.object().when("enterManually", {
        is: false,
        then: () =>
          yup.object().shape({
            name: yup.string().required("Please enter a pharmacy name"),
          }),
      }),
    }),
    onSubmit: (values) => {
      setPharmacyInfo(values);
      navigate(stepMetaData.goNext.linkTo, {
        state: { from: STEPS.pharmacy },
      });
    },
  });

  const onChangePharmacyAutoComplete = (pharmacyGMap) => {
    // console.log("onChangePharmacyAutoComplete", pharmacyGMap);

    if (pharmacyGMap === null) {
      formik.resetForm();
      return;
    }
    if (
      pharmacyGMap.hasOwnProperty("enterManually") &&
      pharmacyGMap.enterManually
    ) {
      formik.setValues({
        ...formik.values,
        enterManually: true,
        pharmacyName: pharmacyGMap.name,
        pharmacyAddress: "",
        pharmacyPhone: "",
      });
      return;
    }
    getPlaceDetails(placeService, pharmacyGMap.place_id, (placeDetails) => {
      formik.setFieldValue("placeDetails", placeDetails);
      formik.setFieldValue("pharmacyPhone", placeDetails?.phone);
    });
    formik.setValues({
      ...formik.values,
      enterManually: false,
      pharmacyGMap: pharmacyGMap,
      pharmacyName: pharmacyGMap.name,
      pharmacyAddress: pharmacyGMap.formatted_address,
    });
  };

  return (
    <StepSC.OuterContainer>
      <StepSC.NavBar>
        <StepSC.LinkTextButton to={stepMetaData.goBack.linkTo}>
          <LeftOutlined /> <span>{stepMetaData.goBack.text}</span>
        </StepSC.LinkTextButton>
        <StepSC.Counter>
          {stepMetaData.step.current} of {stepMetaData.step.total}
        </StepSC.Counter>
      </StepSC.NavBar>
      <StepSC.Hero>
        <StepSC.Title>{stepMetaData.title}</StepSC.Title>
        <StepSC.Description>{stepMetaData.description}</StepSC.Description>
        <StepSC.Form onSubmit={formik.handleSubmit}>
          <PharmacyAutoComplete
            placeService={placeService}
            id="pharmacyGMap"
            name="pharmacyGMap"
            label="Pharmacy"
            value={formik.values.pharmacyGMap}
            onChange={onChangePharmacyAutoComplete}
            handleReset={() =>
              formik.setValues(defaultFormValues[STEPS.pharmacy])
            }
            error={
              formik.touched.pharmacyGMap && Boolean(formik.errors.pharmacyGMap)
            }
            helperText={
              formik.touched.pharmacyGMap && formik.errors.pharmacyGMap?.name
            }
          />
          {formik.values.enterManually ? (
            <StepSC.ActionItems>
              <TextInput
                id="pharmacyName"
                name="pharmacyName"
                label="Pharmacy Name"
                value={formik.values.pharmacyName}
                onChange={formik.handleChange}
                error={
                  formik.touched.pharmacyName &&
                  Boolean(formik.errors.pharmacyName)
                }
                helperText={
                  formik.touched.pharmacyName && formik.errors.pharmacyName
                }
                fullWidth={false}
              />
              <TextInput
                id="pharmacyPhone"
                name="pharmacyPhone"
                label="Pharmacy Phone"
                value={formik.values.pharmacyPhone}
                onChange={formik.handleChange}
                error={
                  formik.touched.pharmacyPhone &&
                  Boolean(formik.errors.pharmacyPhone)
                }
                helperText={
                  formik.touched.pharmacyPhone && formik.errors.pharmacyPhone
                }
                fullWidth={false}
              />
              <TextInput
                id="pharmacyAddress"
                name="pharmacyAddress"
                label="Pharmacy Address"
                value={formik.values.pharmacyAddress}
                onChange={formik.handleChange}
                error={
                  formik.touched.pharmacyAddress &&
                  Boolean(formik.errors.pharmacyAddress)
                }
                helperText={
                  formik.touched.pharmacyAddress &&
                  formik.errors.pharmacyAddress
                }
              />
            </StepSC.ActionItems>
          ) : (
            <StepSC.ActionLongItem>
              {formik.values.pharmacyName ? (
                <>
                  <h3 style={{ margin: 0, marginTop: 20, width: "100%" }}>
                    Selected pharmacy
                  </h3>
                  <p>
                    <b>{formik.values.pharmacyName}</b>
                    {formik.values.pharmacyGMap?.url && (
                      <StepSC.LinkTextButton
                        to={formik.values.pharmacyGMap.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        <ExportOutlined />
                      </StepSC.LinkTextButton>
                    )}
                    <br />
                    {formik.values.pharmacyAddress}
                    <br />
                    {formik.values.pharmacyPhone}
                    <br />
                  </p>
                </>
              ) : (
                <p>No Pharmacy location Selected</p>
              )}
            </StepSC.ActionLongItem>
          )}
          <StepSC.LinkButton onClick={formik.handleSubmit}>
            {stepMetaData.goNext.text}
          </StepSC.LinkButton>
        </StepSC.Form>
      </StepSC.Hero>
    </StepSC.OuterContainer>
  );
}
