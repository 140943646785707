import React from "react";
import styled from "styled-components";
import LogoImage from "../../data/images/universityLogoNoBg.png";
import { useNavigate } from "react-router-dom";

export default function LogoButton({ src = LogoImage, style }) {
  const navigate = useNavigate();
  return (
    <Logo
      src={src}
      alt="logo"
      onClick={() => {
        navigate("/");
      }}
      style={style}
    />
  );
}

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
  ${({ style }) => style}
`;
